import React from 'react';
import { MenuItem } from '@mui/material';
import FilterSelector from './FilterSelector';

export default function GameTypeSelector(props) {
  const gameTypeLabel = 'Тип игры';

  return (
    <FilterSelector
      tag='game-type'
      label={gameTypeLabel}
      val={props.value ?? ''}
      name='gameType'
      handleChange={props.handleChange}
      required={props.required ?? false}
      disabled={props.disabled ?? false}
    >
      <MenuItem value={''}>
        <em>Не выбрано</em>
      </MenuItem>
      {props.gameTypes.map((t) => {
        return (
          <MenuItem key={'type' + t.id} value={t.id}>
            {t.name}
          </MenuItem>
        );
      })}
    </FilterSelector>
  );
}
