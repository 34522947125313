import { Chip } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from '../../Kernel/UserContext';
import { TimeStringToMs } from '../../Kernel/DateTimeHelper';

export default function GameCardInfoChips(props) {
  const game = props.game;
  const participation = props.participation;
  const sx = props.sx ?? { ml: 1, mt: 1 };

  const user = useContext(UserContext);

  return (
    <>
      {Date.now() - new Date(game.startTime) >
        TimeStringToMs(game.duration) && (
        <Chip sx={sx} size='small' label='Завершено' />
      )}
      {game.creator.id === user.id && (
        <Chip sx={sx} color='primary' size='small' label='Я организатор' />
      )}
      {participation.isParticipant && (
        <Chip sx={sx} color='success' size='small' label='Я в игре' />
      )}
      {participation.isReservist && (
        <Chip
          sx={sx}
          variant='outlined'
          color='primary'
          size='small'
          label='Я в резерве'
        />
      )}
    </>
  );
}
