import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseApiUrl } from '../Kernel/RestApiHelper';
import { GetUserName, HasAvatar } from '../Kernel/UserHelper';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { UserContext } from '../Kernel/UserContext';
import { Avatar, Chip, Grid, Stack } from '@mui/material';
import useUnreadChatsCount from '../Hooks/useUnreadChatsCount';

export default function Header({ setUser, setToken, setTopMenuHub }) {
  const logout = () => {
    fetch(baseApiUrl() + '/auth/logout', {
      method: 'POST',
    });

    setUser({
      firstName: '',
      middleName: '',
      lastName: '',
    });

    setToken('');
  };

  const navigate = useNavigate();
  const user = useContext(UserContext);

  const unreadChatsCount = useUnreadChatsCount(setTopMenuHub);

  const [anchorMainEl, setAnchorMainEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMainMenuOpen = Boolean(anchorMainEl);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMainMenuOpen = (event) => {
    setAnchorMainEl(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setAnchorMainEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    handleMainMenuClose();
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuMainId = 'primary-games-places-menu';
  const renderMainMenu = (
    <Menu
      anchorEl={anchorMainEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id={menuMainId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMainMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate('/games');
        }}
      >
        Игры
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate('/places');
        }}
      >
        Локации
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate('/chats');
        }}
      >
        <Stack direction='row' spacing={1}>
          <Typography>Чаты</Typography>
          {unreadChatsCount > 0 && (
            <Chip label={unreadChatsCount} color='error' size='small' />
          )}
        </Stack>
      </MenuItem>
    </Menu>
  );

  const menuAccountId = 'primary-account-menu';
  const renderAccountMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuAccountId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* {user.firstName !== '' && (
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate('/profile');
          }}
          disabled
        >
          Профиль
        </MenuItem>
      )} */}
      {user.firstName !== '' && (
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate('/settings');
          }}
        >
          Настройки
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleMenuClose();
          window.location =
            'https://forms.yandex.ru/u/665a50bf84227c1445ac4874/';
        }}
      >
        Обратная связь
      </MenuItem>
      {user.firstName !== '' && (
        <MenuItem
          onClick={() => {
            logout();
            handleMenuClose();
            navigate('/login');
          }}
        >
          Выйти
        </MenuItem>
      )}
      {user.firstName === '' && (
        <MenuItem
          onClick={() => {
            handleMenuClose();
            const returnUrl = new URLSearchParams({
              returnUrl: window.location.pathname,
            }).toString();

            navigate('/login?' + returnUrl);
          }}
        >
          Вход
        </MenuItem>
      )}
    </Menu>
  );

  const mobileAccountMenuId = 'primary-account-menu-mobile';
  const renderMobileAccountMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileAccountMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Оповещения</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls={menuAccountId}
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' sx={{ bgcolor: '#1b5e20' }}>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='main menu'
            sx={{
              mr: 2,
              display: { xs: 'flex', sm: 'none' },
            }}
            aria-controls={menuMainId}
            aria-haspopup='true'
            onClick={handleMainMenuOpen}
          >
            <Badge
              badgeContent={isMainMenuOpen ? 0 : unreadChatsCount}
              max={9}
              color='error'
            >
              <MenuIcon />
            </Badge>
          </IconButton>
          <Typography
            variant='h6'
            noWrap
            // sx={{ display: { xs: 'none', sm: 'block' } }}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.location = '/';
            }}
          >
            PLAYMAKER
          </Typography>
          <Typography
            variant='h6'
            noWrap
            sx={{ ml: 2, display: { xs: 'none', sm: 'block' } }}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/games');
            }}
          >
            Игры
          </Typography>
          <Typography
            variant='h6'
            noWrap
            sx={{ ml: 2, display: { xs: 'none', sm: 'block' } }}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/places');
            }}
          >
            Локации
          </Typography>
          {user.id && (
            <Badge
              badgeContent={unreadChatsCount}
              max={9}
              color='error'
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              <Typography
                variant='h6'
                noWrap
                sx={{
                  ml: 2,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/chats');
                }}
              >
                Чаты
              </Typography>
            </Badge>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            <Grid container spacing={1} direction='row'>
              {user ? (
                <Grid item alignSelf={'center'}>
                  <Typography
                    variant='h8'
                    noWrap
                    component='div'
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/profile');
                    }}
                  >
                    {GetUserName(user)}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
              {user ? (
                <Grid item>
                  <IconButton
                    size='large'
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={menuAccountId}
                    aria-haspopup='true'
                    onClick={handleProfileMenuOpen}
                    color='inherit'
                  >
                    {user !== null && user && user.avatarSmall ? (
                      <Avatar
                        src={
                          HasAvatar(user)
                            ? baseApiUrl() + '/files/' + user.avatarSmall
                            : undefined
                        }
                      />
                    ) : (
                      <AccountCircle />
                    )}
                  </IconButton>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMainMenu}
      {renderMobileAccountMenu}
      {renderAccountMenu}
    </Box>
  );
}
