import {
  AccessTime,
  Person,
  PersonOutline,
  ReadMore,
} from '@mui/icons-material';
import GameTypeIcon from '../Shared/GameTypeIcon';
import { IconButton, Typography, Stack, Grid } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';

import { getPlayersCount } from './Game';
import GameCardInfoChips from './GameCardInfoChips';

export default function GameMapCard(props) {
  const game = props.game;
  const participation = props.participation;
  const dateTimeOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        width={300}
        spacing={0.5}
        alignItems='center'
        position={'relative'}
      >
        <Grid item xs={12} container spacing={1} alignItems='center'>
          <Grid item xs='auto'>
            <GameTypeIcon
              fontSize='large'
              gameTypeId={game.gameTypeId}
            ></GameTypeIcon>
          </Grid>
          <Grid item xs>
            <Typography
              variant='subtitle1'
              style={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {game.name}
            </Typography>
            <Typography variant='subtitle2' color={'#AAA'}>
              {game.gameTypeName}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <IconButton onClick={() => navigate('/game/' + game.id)}>
              <ReadMore fontSize='large' color='primary'></ReadMore>
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <GameCardInfoChips
            game={game}
            participation={participation}
            sx={{ mr: 1, mb: 0.25 }}
          />
        </Grid>
        <Grid item xs={12} container spacing={1.5} alignItems='center'>
          <Grid item>
            <Stack direction={'row'} spacing={0.5} alignItems='center'>
              <AccessTime color='action' />
              <Typography variant='body2'>
                {new Date(game.startTime).toLocaleString(
                  undefined,
                  dateTimeOptions
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction={'row'} spacing={0.5} alignItems='center'>
              <Person color='action' />
              <Typography variant='body2'>
                {getPlayersCount(
                  participation.participantsCount,
                  game.maxPlayers
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction={'row'} spacing={0.5} alignItems='center'>
              <PersonOutline color='action' />
              <Typography variant='body2'>
                {getPlayersCount(participation.reserveCount, game.maxReserve)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
