import { Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { getAvatar } from '../../../Kernel/ChatHelper';

var timeOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export default function Message({ message, my }) {
  const multilineText = String(message.text).match(/[^\n]+/g);

  return (
    <>
      <Paper sx={{ padding: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack
              direction={my ? 'row-reverse' : 'row'}
              spacing={1}
              alignItems={'center'}
            >
              {getAvatar(message.author.avatar, message.author.name)}
              <Stack>
                <Typography variant='subtitle1'>
                  {message.author.name}
                </Typography>
                <Typography variant='caption'>
                  {new Date(message.timeSent).toLocaleTimeString(
                    [],
                    timeOptions
                  )}
                </Typography>
              </Stack>
            </Stack>
            <Divider></Divider>
          </Grid>

          <Grid item xs={12}>
            {multilineText.map((x, i) => (
              <Typography variant='body1' mt={i > 0 ? 0.5 : 0} key={i}>
                {x}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
