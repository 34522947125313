import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

export default function Notifications({ text, severity, timestamp }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(String(text).length > 0);
  }, [timestamp]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Alert
        onClose={() => {
          setOpen(false);
        }}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
}
