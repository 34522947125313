import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../Kernel/AuthContext';
import { NotificationContext } from '../Kernel/NotificationContext';

export default function useAuthFetch() {
  const getExpirationDate = (jwtToken) => {
    if (!jwtToken) {
      return null;
    }
    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const { token, refreshToken } = useContext(AuthContext);
  const [expiration, setExpiration] = useState(getExpirationDate(token));

  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (token !== '') {
      setExpiration(getExpirationDate(token));
    }
  }, [token]);

  const authFetch = async (input, init) => {
    let authToken = token;
    if (Date.now() > expiration) {
      authToken = await refreshToken();
    }

    init = init || {};

    init.headers = {
      ...init.headers,
      Authorization: `Bearer ${authToken}`,
    };

    return fetch(input, init)
      .then((response) => {
        if (!response.ok) {
          return response.text().then((message) => {
            throw new Error(message);
          });
        }

        if (init.skipParsing) {
          return response;
        }

        if (!init.method || init.method === 'GET' || init.method === 'POST') {
          return response.json();
        }
      })
      .catch((err) => {
        // игнорируем ошибку от вызова abort()
        if (err.name !== 'AbortError') {
          const message = err.message;
          showNotification(message, 'error');
        }

        return new Promise(() => {});
      });
  };

  return authFetch;
}
