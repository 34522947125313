import React from 'react';
import { TopMenuConnectionContext } from './TopMenuConnectionContext';
import { NotificationContext } from './NotificationContext';
import { AuthContext } from './AuthContext';
import { ApiContext } from './ApiContext';
import { UserContext } from './UserContext';

export default function CustomContextProvider({
  topMenuHub,
  showNotification,
  authToken,
  refreshAuthToken,
  chatsApiPath,
  userInfo,
  children,
}) {
  return (
    <TopMenuConnectionContext.Provider value={{ hubConnection: topMenuHub }}>
      <NotificationContext.Provider
        value={{
          showNotification,
        }}
      >
        <AuthContext.Provider
          value={{
            token: authToken,
            refreshToken: refreshAuthToken,
          }}
        >
          <ApiContext.Provider value={{ chatsApi: chatsApiPath }}>
            <UserContext.Provider value={userInfo}>
              {children}
            </UserContext.Provider>
          </ApiContext.Provider>
        </AuthContext.Provider>
      </NotificationContext.Provider>
    </TopMenuConnectionContext.Provider>
  );
}
