import { Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ChatHeader from './CurrentChat/ChatHeader';
import ChatMessages from './CurrentChat/ChatMessages';
import { UserContext } from '../../Kernel/UserContext';
import useAuthFetch from '../../Hooks/useAuthFetch';

export default function CurrentChat({
  chat,
  hubConnection,
  chatsApiUrl,
  showBack,
  onBackPressed,
}) {
  const currentUser = useContext(UserContext);

  const textInput = useRef(null); // здесь используем ссылку, чтобы корректно работал Ctrl+Enter
  const [messages, setMessages] = useState([]);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);

  const send = (message) => {
    if (message.length > 0) {
      hubConnection.invoke('send', chat.id, currentUser.id, message);
      textInput.current.value = '';
    }
  };

  const messageReceived = (m) => {
    setMessages((messages) => [...messages, m]);
    hubConnection.invoke('SetLastVisitTime', currentUser.id, chat.id);
  };

  const keydownHandler = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      send(textInput.current.value);
    }
  };

  const loadMessagesCount = 20;
  const authFetch = useAuthFetch();
  const loadMessages = (ct) => {
    authFetch(chatsApiUrl + '/chats/' + chat.id + '/messages', {
      signal: ct.signal,
    }).then((data) => {
      setMessages(data);
      setShowLoadMoreBtn(data.length === loadMessagesCount);
    });
  };

  const onLoadMore = () => {
    authFetch(
      `${chatsApiUrl}/chats/${chat.id}/messages?take=${loadMessagesCount}&skip=${messages.length}`
    ).then((data) => {
      setMessages([...data, ...messages]);
      setShowLoadMoreBtn(data.length === loadMessagesCount);
    });
  };

  useEffect(() => {
    if (chat.id === undefined) return;

    const ct = new AbortController();
    loadMessages(ct);

    document.addEventListener('keydown', keydownHandler);
    hubConnection.invoke('Connect', currentUser.id, chat.id);
    hubConnection.invoke('SetLastVisitTime', currentUser.id, chat.id);

    hubConnection.on('messageReceived', (m) => messageReceived(m));

    return () => {
      hubConnection.off('messageReceived');
      if (hubConnection.state === 'Connected') {
        hubConnection.invoke('Disconnect', currentUser.id, chat.id);
      }
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [chat]);

  return (
    chat.id !== undefined && (
      <Grid container spacing={2}>
        {showBack && (
          <Grid item xs={1}>
            <IconButton
              size='large'
              color='primary'
              onClick={() => onBackPressed()}
            >
              <ArrowBack></ArrowBack>
            </IconButton>
          </Grid>
        )}
        <Grid item xs={showBack ? 11 : 12}>
          <ChatHeader chat={chat}></ChatHeader>
        </Grid>
        <Grid item xs={12}>
          <ChatMessages
            chatId={chat.id}
            messages={messages}
            currentUser={currentUser}
            showLoadMore={showLoadMoreBtn}
            onLoadMore={onLoadMore}
          ></ChatMessages>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2}>
            <TextField
              variant='outlined'
              label='Сообщение'
              fullWidth
              multiline
              inputRef={textInput}
            ></TextField>
            <Button onClick={() => send(textInput.current.value)}>
              Отправить
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  );
}
