import React, { useEffect, useRef, useState } from 'react';
import Message from './Message';
import { Box, Button, Grid } from '@mui/material';

export default function ChatMessages({
  chatId,
  messages,
  currentUser,
  showLoadMore,
  onLoadMore,
}) {
  const top = useRef(null);
  const bottom = useRef(null);

  const [currentTopId, setCurrentTopId] = useState();

  const onButtonClick = () => {
    onLoadMore();
    if (messages.length > 0) setCurrentTopId(messages[0].id);
  };

  useEffect(() => {
    if (currentTopId) {
      top.current.scrollIntoView();
    } else {
      bottom.current.scrollIntoView();
    }
  }, [messages]);

  useEffect(() => setCurrentTopId(undefined), [chatId]);

  return (
    //todo отслеживать, что сообщение прочитано https://github.com/joshwnj/react-visibility-sensor
    <Box
      sx={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: '450px',
        '&::-webkit-scrollbar': {
          width: 4,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'ligthgray',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'gray',
          borderRadius: 2,
        },
        backgroundColor: '#F8F8F8',
        borderRadius: 2,
      }}
    >
      <Grid container spacing={1} p={1}>
        {showLoadMore && (
          <Grid item xs={12}>
            <Button onClick={() => onButtonClick()} fullWidth>
              Ещё...
            </Button>
          </Grid>
        )}
        {messages.map((x, i) => (
          <Grid
            item
            xs={10}
            sx={{
              marginLeft: x && x.author.id === currentUser.id ? 'auto' : null,
            }}
            key={i}
          >
            {x.id === currentTopId && <div ref={top}></div>}
            <Message
              message={x}
              my={x && x.author.id === currentUser.id}
            ></Message>
          </Grid>
        ))}
      </Grid>
      <div ref={bottom}></div>
    </Box>
  );
}
