import { baseApiUrl } from './RestApiHelper';
import { Avatar } from '@mui/material';

export function getAvatar(image, name) {
  if (
    image &&
    image.length > 0 &&
    image !== '00000000-0000-0000-0000-000000000000'
  ) {
    return <Avatar src={baseApiUrl() + '/files/' + image}></Avatar>;
  }

  const shortedName = String(name)
    .split(' ', 2)
    .map((x) => x.charAt(0))
    .join('');

  return <Avatar>{shortedName}</Avatar>;
}
