import { Stack, Typography } from '@mui/material';
import { getAvatar } from '../../../Kernel/ChatHelper';
import React from 'react';

export default function ChatHeader({ chat }) {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {getAvatar(chat.avatar, chat.name)}
      <Typography variant='h5' textAlign={'center'}>
        {chat.name}
      </Typography>
    </Stack>
  );
}
