import { useContext, useEffect, useState } from 'react';
import useAuthFetch from './useAuthFetch';
import { ApiContext } from '../Kernel/ApiContext';
import useHubConnection from './useHubConnection';
import { UserContext } from '../Kernel/UserContext';

const audio = new Audio(process.env.PUBLIC_URL + '/ball-bounce.mp3');

export default function useUnreadChatsCount(setTopMenuHub) {
  const authFetch = useAuthFetch();
  const { chatsApi } = useContext(ApiContext);
  const user = useContext(UserContext);

  const [unreadChatsCount, setUnreadChatsCount] = useState(0);

  const hubConnection = useHubConnection(chatsApi, 'topMenuHub');

  useEffect(() => {
    setTopMenuHub(hubConnection);
    if (hubConnection) {
      hubConnection.on('unreadChatsCountChanged', () => {
        unreadChatsCountChanged();
      });

      return () => {
        hubConnection.off('unreadChatsCountChanged');
      };
    }
  }, [hubConnection]);

  const unreadChatsCountChanged = () => {
    const ct = new AbortController();
    updateUnreadChatsCount(ct);
  };

  const updateUnreadChatsCount = (ct) => {
    if (user && user.id) {
      authFetch(chatsApi + '/chats/unread', { signal: ct.signal }).then(
        (data) => {
          setUnreadChatsCount(Number(data));
          if (
            unreadChatsCount !== data &&
            window.location.pathname !== '/chats'
          ) {
            audio.play();
          }
        }
      );
    }
  };

  useEffect(() => {
    const ct = new AbortController();
    updateUnreadChatsCount(ct);

    return () => {
      ct.abort();
    };
  }, [chatsApi]);

  return unreadChatsCount;
}
