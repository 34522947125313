import React from 'react';
import {
  CasinoOutlined,
  DirectionsWalkOutlined,
  PedalBike,
  PlaceRounded,
  RunCircleOutlined,
  SelfImprovement,
  SportsBasketballOutlined,
  SportsSoccer,
  SportsTennis,
  SportsVolleyball,
} from '@mui/icons-material';

export default function GameTypeIcon(props) {
  const gameTypes = {
    Football: 1,
    Basketball: 2,
    Volleyball: 3,
    DnD: 4,
    Tennis: 5,
    TableTennis: 6,
    Running: 7,
    Walking: 8,
    Bike: 9,
    Yoga: 10,
  };

  switch (props.gameTypeId) {
    case gameTypes.Football:
      return <SportsSoccer fontSize={props.fontSize} />;
    case gameTypes.Basketball:
      return <SportsBasketballOutlined fontSize={props.fontSize} />;
    case gameTypes.Volleyball:
      return <SportsVolleyball fontSize={props.fontSize} />;
    case gameTypes.DnD:
      return <CasinoOutlined fontSize={props.fontSize} />;
    case gameTypes.Tennis:
    case gameTypes.TableTennis:
      return <SportsTennis fontSize={props.fontSize} />;
    case gameTypes.Running:
      return <RunCircleOutlined fontSize={props.fontSize} />;
    case gameTypes.Walking:
      return <DirectionsWalkOutlined fontSize={props.fontSize} />;
    case gameTypes.Bike:
      return <PedalBike fontSize={props.fontSize} />;
    case gameTypes.Yoga:
      return <SelfImprovement fontSize={props.fontSize} />;

    default:
      return <PlaceRounded fontSize={props.fontSize} />;
  }
}
