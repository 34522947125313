import { Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function Dropdown(props) {
  const hasItems = props.children !== undefined && props.children.length > 0;
  const [show, setShow] = useState(true);

  return (
    <div>
      <Button
        variant='outlined'
        disabled={!hasItems}
        onClick={() => setShow(!show)}
        fullWidth
        color='info'
      >
        {props.title}
        <ArrowDropDownIcon />
      </Button>

      {show && hasItems && (
        <ol>
          {props.children.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
      )}
    </div>
  );
}

export default Dropdown;
