import React, { useState, useEffect } from 'react';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import useAuthFetch from '../../Hooks/useAuthFetch';
import {
  FormControlLabel,
  Grid,
  Typography,
  Switch,
  Select,
  MenuItem,
  Skeleton,
  Stack,
  Button,
  Paper,
} from '@mui/material';
import FilterSelector from '../Shared/FilterSelector';
import AddressFilter from '../Shared/AddressFilter';

export default function PlaceFilter(props) {
  const { filter, setFilter } = { ...props };
  const [gameTypes, setGameTypes] = useState([
    { id: '', name: 'Без фильтрации' },
  ]);
  const [loadingGameTypes, setLoadingGameTypes] = useState(true);

  const [useGeozone, setUseGeozone] = useState(false);

  const authFetch = useAuthFetch();

  const gameTypeLabel = 'Тип игры';
  const paingLabel = 'Необходимость оплаты';

  const handleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleUseGeozoneSwitch = (e) => {
    setUseGeozone(e.target.checked);
    if (props.onUseGeozone) props.onUseGeozone(e.target.checked);
  };

  useEffect(() => {
    const ct = new AbortController();
    authFetch(baseApiUrl() + '/gametypes', { signal: ct.signal }).then(
      (data) => {
        setGameTypes(data);
        setLoadingGameTypes(false);
      }
    );

    return () => {
      ct.abort();
    };
  }, []);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6'>Фильтры</Typography>
        </Grid>
        <Grid item xs={12}>
          {loadingGameTypes ? (
            <Skeleton variant='rounded' animation='wave' width={'100%'}>
              <Select size='small' value={''}></Select>
            </Skeleton>
          ) : (
            <FilterSelector
              tag='game-type'
              label={gameTypeLabel}
              val={filter.gameType ?? ''}
              name='gameType'
              handleChange={handleChange}
              disabled={
                props.settings && props.settings.blockGameType
                  ? props.settings.blockGameType
                  : false
              }
            >
              <MenuItem value={''}>
                <em>Без фильтрации</em>
              </MenuItem>
              {gameTypes.map((t) => {
                return (
                  <MenuItem key={'type' + t.id} value={t.id}>
                    {t.name}
                  </MenuItem>
                );
              })}
            </FilterSelector>
          )}
        </Grid>
        <AddressFilter filter={filter} setFilter={setFilter}></AddressFilter>
        <Grid item xs={12}>
          <FilterSelector
            tag='paing'
            label={paingLabel}
            val={filter.isPaid ?? ''}
            name='isPaid'
            handleChange={handleChange}
          >
            <MenuItem value={''}>
              <em>Все равно</em>
            </MenuItem>
            <MenuItem value={true}>Готов заплатить</MenuItem>
            <MenuItem value={false}>Не готов платить</MenuItem>
          </FilterSelector>
        </Grid>
        {!(props.settings && props.settings.hideGeoZone) && (
          <Grid item xs={12}>
            <Stack direction='row'>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleUseGeozoneSwitch}
                    name='useGeozone'
                    checked={useGeozone ?? false}
                  />
                }
                label='Использовать указанную на карте зону поиска'
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            fullWidth
            size='normal'
            onClick={() => {
              setFilter({});
            }}
          >
            Сбросить
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
