import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { HighlightOff } from '@mui/icons-material';

import { baseApiUrl } from '../../../Kernel/RestApiHelper';
import useAuthFetch from '../../../Hooks/useAuthFetch';

export default function SessionsManager({ userId }) {
  const [sessions, setSessions] = useState([]);

  const authFetch = useAuthFetch();

  const fetchSessions = (ct) => {
    authFetch(`${baseApiUrl()}/user/${userId}/sessions`, {
      signal: ct.signal,
    }).then((data) => {
      setSessions(data);
    });
  };

  const closeSession = (id) => {
    authFetch(`${baseApiUrl()}/user/${userId}/sessions/${id}`, {
      method: 'DELETE',
    }).then(() => fetchSessions(new AbortController()));
  };

  useEffect(() => {
    let controller = new AbortController();
    fetchSessions(controller);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
              <b>Последняя активность</b>
            </TableCell>
            <TableCell align='center'>
              <b>IP-адрес</b>
            </TableCell>
            <TableCell align='center'>
              <b>Браузер</b>
            </TableCell>
            <TableCell align='center'>
              <b>ОС</b>
            </TableCell>
            <TableCell align='center'>
              <b>Завершить</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell align='center'>
                {new Date(row.lastLoginTime).toLocaleString()}
              </TableCell>
              <TableCell align='center'>{row.ip}</TableCell>
              <TableCell align='center'>{row.browser}</TableCell>
              <TableCell align='center'>{row.os}</TableCell>
              <TableCell align='center'>
                <IconButton color='error' onClick={() => closeSession(row.id)}>
                  <HighlightOff></HighlightOff>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
