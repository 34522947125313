import React, { useState, useEffect } from 'react';
import { baseApiUrl } from '../../Kernel/RestApiHelper';

import {
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Switch,
  Select,
  Skeleton,
  Stack,
  Button,
  Paper,
} from '@mui/material';
import GameTypeSelector from '../Shared/GameTypeSelector';
import useAuthFetch from '../../Hooks/useAuthFetch';
import AddressFilter from '../Shared/AddressFilter';

export default function GameFilter(props) {
  const { filter, setFilter } = { ...props };
  const [gameTypes, setGameTypes] = useState([
    { id: '', name: 'Без фильтрации' },
  ]);

  const [loadingGameTypes, setLoadingGameTypes] = useState(true);

  const myGamesEnum = {
    none: 0,
    org: 1,
    player: 2,
    reserve: 4,
  };
  const myGames = filter.myGames ?? myGamesEnum.none;

  const defaultFilter = props.default ?? {};

  const authFetch = useAuthFetch();

  const fetchData = (ct) => {
    authFetch(baseApiUrl() + '/gametypes', { signal: ct.signal }).then(
      (data) => {
        setGameTypes(data);
        setLoadingGameTypes(false);
      }
    );
  };

  const handleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitch = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.checked });
  };

  const handleToggle = (v) => {
    // побитовые операции. Если флаг v есть в енаме, то убираем его,
    // а если нет, то добавляем
    const values = myGames & v ? myGames ^ v : myGames | v;
    setFilter({ ...filter, myGames: values });
  };

  useEffect(() => {
    let controller = new AbortController();

    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='h6'>Мои игры</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleToggle(myGamesEnum.org)}
              variant={myGames & myGamesEnum.org ? 'contained' : 'text'}
              color={myGames & myGamesEnum.org ? 'primary' : 'inherit'}
            >
              <Typography variant='body' noWrap>
                организатор
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleToggle(myGamesEnum.player)}
              variant={myGames & myGamesEnum.player ? 'contained' : 'text'}
              color={myGames & myGamesEnum.player ? 'success' : 'inherit'}
            >
              <Typography variant='body' noWrap>
                в игре
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleToggle(myGamesEnum.reserve)}
              variant={myGames & myGamesEnum.reserve ? 'outlined' : 'text'}
              color={myGames & myGamesEnum.reserve ? 'primary' : 'inherit'}
            >
              <Typography variant='body' noWrap>
                в резерве
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Фильтры</Typography>
        </Grid>
        <Grid item xs={12}>
          {loadingGameTypes ? (
            <Skeleton variant='rounded' animation='wave' width={'100%'}>
              <Select size='small' value={''}></Select>
            </Skeleton>
          ) : (
            <>
              <GameTypeSelector
                value={filter.gameType}
                handleChange={handleChange}
                gameTypes={gameTypes}
              ></GameTypeSelector>
            </>
          )}
        </Grid>
        <AddressFilter filter={filter} setFilter={setFilter}></AddressFilter>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Дата игры:</Typography>
          </Grid>
          <Grid item>
            <Stack spacing={1} direction='row'>
              <Typography variant='subtitle1'>от</Typography>
              <TextField
                type='date'
                name='dateFrom'
                size='small'
                onChange={handleChange}
                value={filter.dateFrom ?? ''}
              ></TextField>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={1} direction='row'>
              <Typography variant='subtitle1'>до</Typography>
              <TextField
                type='date'
                name='dateTo'
                size='small'
                onChange={handleChange}
                value={filter.dateTo ?? ''}
              ></TextField>
            </Stack>
          </Grid>
          {/* <Typography variant='subtitle1'>Дата игры:</Typography> */}
          {/* <Stack spacing={1} direction='row'>
            <Typography variant='subtitle1'>от</Typography>
            <TextField
              type='date'
              name='dateFrom'
              size='small'
              onChange={handleChange}
              value={filter.dateFrom ?? ""}
            ></TextField>
            <Typography variant='subtitle1'>до</Typography>
            <TextField
              type='date'
              name='dateTo'
              size='small'
              onChange={handleChange}
              value={filter.dateTo ?? ""}
            ></TextField>
          </Stack> */}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Наличие свободных мест:</Typography>
          <Stack direction='row'>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitch}
                  name='canParticipate'
                  color='success'
                  checked={filter.canParticipate ?? false}
                />
              }
              label='Участие'
            />
            <FormControlLabel
              control={
                <Switch
                  onChange={handleSwitch}
                  name='canReserve'
                  checked={filter.canReserve ?? false}
                />
              }
              label='Резерв'
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            size='normal'
            onClick={() => {
              setFilter(defaultFilter);
            }}
          >
            Сбросить
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
