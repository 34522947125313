import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordInput from './PasswordInput';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import {
  Typography,
  FormControl,
  Button,
  Grid,
  Box,
  TextField,
  Stack,
  LinearProgress,
} from '@mui/material';
import AuthAlert from './AuthAlert';

export default function ResetPassword() {
  const [data, setData] = useState(
    window.location.search.length > 0
      ? Object.fromEntries(new URLSearchParams(window.location.search))
      : {}
  );

  const [processing, setProcessing] = useState(false);

  const [info, setInfo] = useState({
    text: '',
    severity: 'info',
  });

  const verified = data.userId && data.code;

  const navigate = useNavigate();

  const api = baseApiUrl() + '/auth/reset_password/';

  const notify = (text, severity) => {
    setInfo({ text, severity, timestamp: Date.now() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (verified) {
      resetPassword();
    } else {
      //отправить ссылку на восстановление
      setProcessing(true);
      fetch(api + 'send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(data.email),
      }).then((response) => {
        if (response.ok) {
          notify('Ссылка на восстановление отправлена на Вашу почту', 'info');
        } else {
          notify(
            'Произошла ошибка. Попробуйте отправить ссылку снова.',
            'error'
          );
        }
        setProcessing(false);
      });
    }
  };

  const resetPassword = () => {
    setProcessing(true);
    fetch(api + 'change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          notify('Пароль успешно изменен', 'success');
          setTimeout(() => navigate('/login'), 3000);
        } else {
          notify(
            'Произошла ошибка. Попробуйте отправить ссылку снова.',
            'error'
          );
          setData({});
        }
        setProcessing(false);
      })
      .catch((err) => alert(err));
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <Grid container justifyContent='center'>
      <Grid
        item
        xs={12}
        md={8}
        textAlign='center'
        justifyContent='center'
        container
        spacing={3}
      >
        <Grid item xs={12} md={10}>
          <Typography variant='h3'>Восстановись, чтобы быть в игре</Typography>
          <Typography>
            Придумай новый пароль и используй его в следующий раз
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={5}>
          <Box component='form' onSubmit={handleSubmit}>
            <Stack spacing={1}>
              {!verified && (
                <FormControl>
                  <TextField
                    id='email-input'
                    name='email'
                    type='email'
                    onChange={(e) => handleChange(e)}
                    label='Почта'
                    required
                  />
                </FormControl>
              )}
              {verified && (
                <PasswordInput
                  label='Новый пароль'
                  value={data.password}
                  onChange={(e) => handleChange(e)}
                  noRequired={true}
                />
              )}
              <FormControl>
                <Button
                  variant='contained'
                  size='large'
                  type='submit'
                  disabled={processing}
                >
                  {verified ? 'Сохранить пароль' : 'Отправить ссылку'}
                </Button>
                {processing && <LinearProgress></LinearProgress>}
              </FormControl>
              <AuthAlert
                text={info.text}
                severity={info.severity}
                timestamp={info.timestamp}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
