export function GetUserName(user) {
  return (
    user.firstName +
    ' ' +
    (user.middleName && user.middleName.length > 0
      ? user.middleName + ' '
      : '') +
    (user.lastName && user.lastName.length > 0 ? user.lastName : '')
  );
}

export function HasAvatar(user) {
  return (
    user.avatarFull &&
    user.avatarFull !== '00000000-0000-0000-0000-000000000000'
  );
}
