import { CheckCircle, Error } from '@mui/icons-material';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailChanger from './Settings/EmailChanger';
import { UserContext } from '../../Kernel/UserContext';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import { NotificationContext } from '../../Kernel/NotificationContext';

export default function ConfirmEmail({ enableMenu }) {
  const data =
    window.location.search.length > 0
      ? Object.fromEntries(new URLSearchParams(window.location.search))
      : {};

  const user = useContext(UserContext);

  const [confirmed, setConfirmed] = useState(false);
  const [showEmailChanger, setShowEmailChanger] = useState(false);

  const { showNotification } = useContext(NotificationContext);

  const navigate = useNavigate();

  const resend = () => {
    const api = baseApiUrl() + '/auth/verify_email/' + user.id;
    fetch(api, {
      method: 'POST',
    }).then((response) => {
      if (response.ok) {
        showNotification('Ссылка отправлена вам на почту.', 'success');
      } else {
        showNotification(
          'Произошла ошибка. Попробуйте отправить ссылку снова.',
          'error'
        );
      }
    });
  };

  const confirm = (ct) => {
    var params = new URLSearchParams(data);
    const api = baseApiUrl() + '/auth/verify_email?' + params.toString();
    fetch(api, {
      method: 'POST',
      signal: ct.signal,
    }).then((response) => {
      if (response.ok) {
        setConfirmed(true);
        enableMenu();
      } else {
        showNotification(
          'Произошла ошибка. Попробуйте отправить ссылку снова.',
          'error'
        );
      }
    });
  };

  useEffect(() => {
    let controller = new AbortController();
    if (data.userId && data.code) {
      confirm(controller);
    }
    return () => {
      controller.abort();
    };
  }, [user]);

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={12} md={8} lg={6} mt={10}>
        <Paper sx={{ padding: 5 }}>
          <Stack direction={{ xs: 'column', md: 'column' }} spacing={2}>
            <Typography variant='h3' textAlign={'center'}>
              {confirmed && data.code ? (
                <CheckCircle fontSize='inherit' color='success'></CheckCircle>
              ) : (
                <Error fontSize='inherit' color='error'></Error>
              )}
            </Typography>
            <Typography variant={confirmed ? 'h4' : 'h5'} textAlign={'center'}>
              {data.code
                ? confirmed
                  ? 'E-mail успешно подтвержден!'
                  : 'Ошибка подтверждения e-mail. Попробуйте отправить ссылку снова'
                : 'Требуется подтвердить e-mail. Ссылка отправлена на почту.'}
            </Typography>
          </Stack>
          <Stack direction={'row-reverse'} mt={2} spacing={2}>
            {confirmed && (
              <Button
                variant='contained'
                size='large'
                color='success'
                onClick={() => navigate('/games')}
              >
                <Typography variant='h6' textAlign={'center'}>
                  К играм!
                </Typography>
              </Button>
            )}
            {!confirmed && (
              <>
                <Button
                  variant='contained'
                  size='large'
                  onClick={() => resend()}
                >
                  <Typography variant='h7' textAlign={'center'}>
                    Отправить снова
                  </Typography>
                </Button>
                {user.id && (
                  <Button
                    variant='outlined'
                    size='large'
                    onClick={() => setShowEmailChanger(true)}
                  >
                    <Typography variant='h7' textAlign={'center'}>
                      Сменить e-mail
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Stack>
          {!confirmed && (
            <Typography variant='subtitle2' textAlign={'center'} mt={3}>
              Если ссылка не приходит - проверьте попадание письма в "Спам"
            </Typography>
          )}
        </Paper>
        <EmailChanger
          open={showEmailChanger}
          setOpen={setShowEmailChanger}
          userId={user.id}
          refreshUser={() => {}}
          showInfo={(severity, text) => showNotification(text, severity)}
        />
      </Grid>
    </Grid>
  );
}
