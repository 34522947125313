import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import { baseApiUrl } from '../../../Kernel/RestApiHelper';
import PasswordInput from '../../Auth/PasswordInput';
import useAuthFetch from '../../../Hooks/useAuthFetch';

export default function PasswordChanger(props) {
  const { open, setOpen, userId, refreshUser, showInfo } = { ...props };

  const [data, setData] = useState({});
  const [updating, setUpdating] = useState(false);

  const authFetch = useAuthFetch();

  const update = () => {
    setUpdating(true);
    authFetch(baseApiUrl() + '/user/' + userId + '/password', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(() => {
      refreshUser();
      setUpdating(false);
      setOpen(false);
      showInfo('success', 'Пароль успешно изменен!');
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Изменить пароль</DialogTitle>
      <DialogContent>
        <Stack spacing={2} marginTop={1}>
          <PasswordInput
            label='Старый пароль'
            noRequired
            onChange={(e) => setData({ ...data, password: e.target.value })}
            id='old_password'
          ></PasswordInput>
          <PasswordInput
            label='Новый пароль'
            noRequired
            onChange={(e) => setData({ ...data, value: e.target.value })}
            id='new_password'
          ></PasswordInput>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} disabled={updating}>
          Назад
        </Button>
        <Button
          variant='contained'
          onClick={() => update()}
          disabled={updating}
        >
          Изменить
        </Button>
      </DialogActions>
    </Dialog>
  );
}
