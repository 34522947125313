import React from "react";
import L from "leaflet";
import { FeatureGroup, Rectangle } from "react-leaflet";
import { EditControl } from "react-leaflet-draw"

let canCreateNewGeoZone = true; // вынес сюда, так как при переключении между картой и карточкой 
                                // затирается MapEditCtrls и ее параметры и нужно заново отрисовать геозону.
export default function MapEditCtrls(props) {

    const getMinVal = (valToSet, valToCompare) =>
    {
        return valToSet === undefined
            ? valToCompare
            : Math.min(valToSet, valToCompare);
    }

    const getMaxVal = (valToSet, valToCompare) =>
    {
        return valToSet === undefined
            ? valToCompare
            : Math.max(valToSet, valToCompare);
    }

    const geozoneFromGetLatLngsFrmt = (pts)=>{
        if(!pts)
            return {};
        let res = {
            latitudeFrom: undefined,
            latitudeTo: undefined,
            longitudeFrom: undefined,
            longitudeTo: undefined,
          }
        pts.forEach(pt => {
            if(pt){
                res.latitudeFrom = getMinVal(res.latitudeFrom, pt.lat);
                res.latitudeTo = getMaxVal(res.latitudeTo, pt.lat);
                res.longitudeFrom = getMinVal(res.longitudeFrom, pt.lng);
                res.longitudeTo = getMaxVal(res.longitudeTo, pt.lng);
            }
        });
        return res;
    }

    const _onChange = geoz => {
        let gz = geozoneFromGetLatLngsFrmt(geoz)
        if(props.onChangeGeozone)
            props.onChangeGeozone(gz);
	};

	const _onCreated = e => {
        _onChange(e.layer.getLatLngs()[0]);
        canCreateNewGeoZone = false;
	};

    const _onEdited = e => {
		e.layers.eachLayer(layer => {
            _onChange(layer.getLatLngs()[0]);
		});
	};

	const _onDeleted = e => {
		let numDeleted = 0;
		e.layers.eachLayer(layer => {
			numDeleted += 1;
		});
		console.log(`onDeleted: removed ${numDeleted} layers`, e);

        _onChange(undefined);
        canCreateNewGeoZone = true;
	};

	// const _onMounted = drawControl => {
	// 	console.log("_onMounted", drawControl);
	// };

	// const _onEditStart = e => {
	// 	console.log("_onEditStart", e);
	// };

	// const _onEditStop = e => {
	// 	console.log("_onEditStop", e);
	// };

	// const _onDeleteStart = e => {
	// 	console.log("_onDeleteStart", e);
	// };

	// const _onDeleteStop = e => {
	// 	console.log("_onDeleteStop", e);
	// };

	// const _onDrawStart = e => {
	// 	console.log("_onDrawStart", e);
	// };

    return (
        <FeatureGroup>
            <EditControl
                position='topright'
                onEdited={_onEdited}
                onCreated={_onCreated}
                onDeleted={_onDeleted}
                draw={{
                rectangle: canCreateNewGeoZone ? {
                    icon: new L.DivIcon({
                    iconSize: new L.Point(8, 8),
                    className: "leaflet-div-icon leaflet-editing-icon"
                    }),
                    shapeOptions: {
                    guidelineDistance: 10,
                    color: "blue",
                    weight: 3
                    }
                } : false,
                polyline: false,
                circlemarker: false,
                circle: false,
                marker: false,
                polygon: false
                }}
            />
            {props.geozone && !canCreateNewGeoZone && 
                <Rectangle bounds={[
                    [props.geozone.latitudeFrom, props.geozone.longitudeFrom],
                    [props.geozone.latitudeTo, props.geozone.longitudeTo],
                ]}/>
            }
        </FeatureGroup>
    );
  }
