import { Person, PersonOutline } from '@mui/icons-material';
import GameTypeIcon from '../Shared/GameTypeIcon';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Grid,
} from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';

import { getPlayersCount } from './Game';
import GameCardInfoChips from './GameCardInfoChips';
import { GetAddressString } from '../../Kernel/AddressHelper';

export default function GameCard(props) {
  const game = props.game;
  const participation = props.participation;

  const navigate = useNavigate();

  const buttons = (
    <>
      <Button onClick={() => navigate('/game/' + game.id)}>Подробнее</Button>
      <Button
        onClick={(e) => {
          props.onShowPlaceOnMap(game.place);
        }}
      >
        На карте
      </Button>
    </>
  );

  return (
    <Card>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={3}
          container
          spacing={0.5}
          alignItems='center'
          padding={2}
        >
          <Grid item xs={12} container spacing={1} alignItems='center'>
            <Grid item xs='auto'>
              <GameTypeIcon
                fontSize='large'
                gameTypeId={game.gameTypeId}
              ></GameTypeIcon>
            </Grid>
            <Grid item xs>
              <Typography
                variant='subtitle1'
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {game.name}
              </Typography>
              <Typography variant='subtitle2' color={'#AAA'}>
                {game.gameTypeName}
              </Typography>
            </Grid>
            <Grid item>
              <CardActions sx={{ display: { lg: 'none', xs: 'block' } }}>
                {buttons}
              </CardActions>
            </Grid>
          </Grid>
          <Grid item>
            <GameCardInfoChips
              game={game}
              participation={participation}
              sx={{ mr: 1, mb: 0.5 }}
            />
          </Grid>
          <Grid item lg='auto'>
            <CardActions sx={{ display: { lg: 'block', xs: 'none' }, pl: 0 }}>
              {buttons}
            </CardActions>
          </Grid>
        </Grid>
        <Grid item xs={12} lg>
          <CardContent>
            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={0.5} alignItems='center'>
                  <Typography variant='body2'>
                    <b>Дата и время:</b>{' '}
                    {new Date(game.startTime).toLocaleString()}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={0.5} alignItems='center'>
                  <Typography variant='body2'>
                    <b>Адрес:</b> {GetAddressString(game.place.address)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction={'row'} spacing={0.5} alignItems='center'>
                  <Person color='action' />
                  <Typography variant='body2'>
                    <b>Участники:</b>{' '}
                    {getPlayersCount(
                      participation.participantsCount,
                      game.maxPlayers
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction={'row'} spacing={0.5} alignItems='center'>
                  <PersonOutline color='action' />
                  <Typography variant='body2'>
                    <b>Резерв:</b>{' '}
                    {getPlayersCount(
                      participation.reserveCount,
                      game.maxReserve
                    )}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
