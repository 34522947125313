import React, { useContext, useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import ChatList from './ChatList';
import CurrentChat from './CurrentChat';
import { ApiContext } from '../../Kernel/ApiContext';
import useHubConnection from '../../Hooks/useHubConnection';

export default function Chats() {
  const [selectedChat, setSelectedChat] = useState({});
  const { chatsApi } = useContext(ApiContext);

  const hubConnection = useHubConnection(chatsApi, 'chatHub');

  const theme = useTheme();
  const isLessThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const showEnum = { chatList: 1, currentChat: 2, all: 3 };
  const [show, setShow] = useState(showEnum.all);

  useEffect(() => {
    setShow(
      isLessThanMd
        ? selectedChat.id === undefined
          ? showEnum.chatList
          : showEnum.currentChat
        : showEnum.all
    );
  }, [isLessThanMd]);

  const onChatSelection = (chat) => {
    setSelectedChat(chat);
    if (isLessThanMd) {
      setShow(showEnum.currentChat);
    }
  };

  const onBackPressed = () => {
    if (isLessThanMd) {
      setShow(showEnum.chatList);
    }
  };

  return (
    <Grid container mt={4} justifyContent={'center'} mb={5}>
      <Grid item xs={12} md={10} container justifyContent={'center'}>
        {(show & showEnum.chatList) === showEnum.chatList && (
          <Grid item md={4}>
            <ChatList
              setSelectedChat={onChatSelection}
              chatsApiUrl={chatsApi}
              hubConnection={hubConnection}
            ></ChatList>
          </Grid>
        )}
        {(show & showEnum.currentChat) === showEnum.currentChat && (
          <Grid item md={8}>
            <CurrentChat
              chat={selectedChat}
              hubConnection={hubConnection}
              chatsApiUrl={chatsApi}
              showBack={isLessThanMd}
              onBackPressed={onBackPressed}
            ></CurrentChat>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
