import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function PasswordInput(props) {
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [showPsw, setShowPsw] = useState(false);

  const availableSymbols = '+!@#$%^&*=_';

  return (
    <FormControl>
      <InputLabel htmlFor={props.id ?? 'password-input'}>
        {(props.label ?? 'Пароль') + (props.noRequired ? ' *' : '')}
      </InputLabel>
      <OutlinedInput
        id={props.id ?? 'password-input'}
        name='password'
        type={showPsw ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPsw(!showPsw)}
              edge='end'
            >
              {showPsw ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        onKeyUp={(e) => {
          setIsCapsLockOn(e.getModifierState('CapsLock'));
        }}
        label={props.label ?? 'Пароль'}
        inputProps={{
          minLength:6,
          maxLength:16,
          pattern:'([a-zA-Z0-9' + availableSymbols + ']*)',
          title:'Пароль должен быть не меньше 6 символов и не больше 16; состоять только из латинских символов или цифр или символов ' + availableSymbols
        }}
        onChange={props.onChange}        
        required
      />
      {isCapsLockOn && (
        <Alert severity='warning'>
          Обратите внимание: <strong>Caps Lock включен</strong>
        </Alert>
      )}
    </FormControl>
  );
}
