import { Badge } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TopMenuConnectionContext } from '../../Kernel/TopMenuConnectionContext';

export default function UserOnlineBadge({ userId, children }) {
  const [online, setOnline] = useState(false);

  const { hubConnection } = useContext(TopMenuConnectionContext);

  useEffect(() => {
    if (hubConnection) {
      hubConnection.on('userOnline', (id) => {
        if (id === userId) {
          setOnline(true);
        }
      });

      hubConnection.on('userOffline', (id) => {
        if (id === userId) {
          setOnline(false);
        }
      });

      hubConnection.invoke('IsUserOnline', userId);

      return () => {
        hubConnection.off('userOnline');
        hubConnection.off('userOffline');
      };
    }
  }, [hubConnection]);

  return (
    <Badge
      color='success'
      variant='dot'
      invisible={!online}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </Badge>
  );
}
