import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function GotoLoginDialog({ actionName, open, setOpen }) {
  const navigate = useNavigate();
  const close = () => {
    setOpen(false);
  };

  const onLoginClick = () => {
    close();
    const returnUrl = new URLSearchParams({
      returnUrl: window.location.pathname,
    }).toString();
    navigate('/login?' + returnUrl);
  };

  const onRegisterClick = () => {
    close();
    navigate('/register');
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography variant='h5'>Войдите в аккаунт</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {actionName} доступно только зарегистрированным пользователям.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Назад</Button>
        <Box sx={{ flex: 1 }}></Box>
        <Button color='success' onClick={onLoginClick}>
          Войти
        </Button>
        <Button color='success' onClick={onRegisterClick}>
          Зарегистрироваться
        </Button>
      </DialogActions>
    </Dialog>
  );
}
