import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import PasswordInput from './PasswordInput';
import {
  Typography,
  FormControl,
  Button,
  Grid,
  Box,
  TextField,
  Stack,
  Divider,
  LinearProgress,
} from '@mui/material';
import AuthAlert from './AuthAlert';

export default function Register() {
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [info, setInfo] = useState({
    text: '',
    severity: 'info',
  });
  const notify = (text, severity) => {
    setInfo({ text, severity, timestamp: Date.now() });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setProcessing(true);
    fetch(baseApiUrl() + '/auth/register', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        setProcessing(false);
        if (!response.ok) {
          const message = await response.text();
          throw new Error(message);
        }

        notify('Успешная регистрация!', 'success');
        setTimeout(() => navigate('/login'), 3000);
      })
      .catch((err) => {
        const message = err.message;
        notify(message, 'error');
      });
  };

  return (
    <Grid container justifyContent='center' m={5}>
      <Grid item xs={12} md={8}>
        <Box component='form' onSubmit={onSubmit} autoComplete={false}>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} md={10}>
              <Typography variant='h4' textAlign='center'>
                Заполните данные регистрации
              </Typography>
              <Typography variant='subtitle2' textAlign='center'>
                Помеченные звёздочкой (*) поля являются обязательными
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={5}>
              <Stack spacing={2}>
                <FormControl>
                  <TextField
                    id='email-input'
                    name='email'
                    type='email'
                    onChange={(e) => handleChange(e)}
                    label='Почта'
                    required
                  />
                </FormControl>
                <PasswordInput
                  value={data.password}
                  onChange={(e) => handleChange(e)}
                  noRequired={true}
                />
                <Divider />
                <FormControl>
                  <TextField
                    id='fn-input'
                    name='FirstName'
                    type='text'
                    onChange={(e) => handleChange(e)}
                    label='Имя'
                    required
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id='mn-input'
                    name='MiddleName'
                    type='text'
                    onChange={(e) => handleChange(e)}
                    label='Отчество'
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id='ln-input'
                    name='LastName'
                    type='text'
                    onChange={(e) => handleChange(e)}
                    label='Фамилия'
                  />
                </FormControl>
                <Button
                  variant='contained'
                  type='submit'
                  size='large'
                  fullWidth={false}
                >
                  Зарегистрироваться
                </Button>
                {processing && <LinearProgress></LinearProgress>}
                <AuthAlert
                  text={info.text}
                  severity={info.severity}
                  timestamp={info.timestamp}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
