import {
  Badge,
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getAvatar } from '../../Kernel/ChatHelper';
import useAuthFetch from '../../Hooks/useAuthFetch';
import UserOnlineBadge from '../Shared/UserOnlineBadge';
import { UserContext } from '../../Kernel/UserContext';

const audio = new Audio(process.env.PUBLIC_URL + '/ball-bounce.mp3');

export default function ChatList({
  setSelectedChat,
  chatsApiUrl,
  hubConnection,
}) {
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);

  const authFetch = useAuthFetch();
  const loadChats = (showLoading, ct) => {
    if (showLoading) setLoading(true);
    authFetch(chatsApiUrl + '/chats', { signal: ct.signal }).then((data) => {
      setChats(data);
      if (showLoading) setLoading(false);
    });
  };

  const currentUser = useContext(UserContext);

  const updateUnreadCount = (chatIdCalledUpdate) => {
    const ct = new AbortController();
    loadChats(false, ct);

    if (
      chatIdCalledUpdate !== undefined &&
      selected !== undefined &&
      chatIdCalledUpdate !== selected
    ) {
      audio.play();
    }
  };

  useEffect(() => {
    const ct = new AbortController();
    loadChats(true, ct);

    return () => {
      ct.abort();
    };
  }, [chatsApiUrl]);

  useEffect(() => {
    hubConnection.on('updateUnreadCount', (chatIdCalledUpdate) =>
      updateUnreadCount(chatIdCalledUpdate)
    );

    const externalSelected =
      window.location.search.length > 0
        ? Object.fromEntries(new URLSearchParams(window.location.search)).id
        : undefined;
    const chatFromExternal = chats.find((x) => x.id == externalSelected);
    if (chatFromExternal) {
      setSelected(Number(externalSelected));
      setSelectedChat(chatFromExternal);
      window.history.replaceState('', '', window.location.pathname);
    }

    return () => {
      hubConnection.off('updateUnreadCount');
    };
  }, [chats]);

  const handleClick = (id) => {
    setSelected(id);
    setSelectedChat(chats.find((x) => x.id == id));
  };

  const getOpponentId = (members) => {
    const opponent = members.find((x) => x.id !== currentUser.id);
    return opponent.id;
  };

  return (
    <>
      <Box
        sx={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          maxHeight: '575px',
          '&::-webkit-scrollbar': {
            width: 4,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'ligthgray',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'gray',
            borderRadius: 2,
          },
          // backgroundColor: '#F8F8F8',
          // borderRadius: 2,
        }}
      >
        {loading && <LinearProgress />}

        <List>
          {!loading && chats.length === 0 && (
            <Typography variant='h6' textAlign={'center'}>
              Чаты не найдены
            </Typography>
          )}
          {chats.map((x) => (
            <ListItem disablePadding key={x.id}>
              <ListItemButton
                selected={selected === x.id}
                onClick={() => handleClick(x.id)}
              >
                <ListItemAvatar>
                  <Badge color='success' badgeContent={x.unread} max={99}>
                    {x.type === 0 ? (
                      <UserOnlineBadge userId={getOpponentId(x.members)}>
                        {getAvatar(x.avatar, x.name)}
                      </UserOnlineBadge>
                    ) : (
                      getAvatar(x.avatar, x.name)
                    )}
                  </Badge>
                </ListItemAvatar>
                <ListItemText primary={x.name}></ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
}
