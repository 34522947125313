export function GetAddressString(address) {
  const arr = [];
  if (address) {
    if (address.country) arr.push(address.country);
    if (address.region) arr.push(address.region);
    if (address.city && address.city !== address.region) arr.push(address.city);
    if (address.road) arr.push(address.road);
    if (address.house) arr.push(address.house);
  }
  return arr.join(', ');
}
