import React, { useContext } from 'react';
import { ApiContext } from '../../Kernel/ApiContext';
import useAuthFetch from '../../Hooks/useAuthFetch';
import { Button } from '@mui/material';
import { Chat } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function StartPersonalChatButton({ userId }) {
  const { chatsApi } = useContext(ApiContext);
  const authFetch = useAuthFetch();
  const navigate = useNavigate();

  const handleChatClick = () => {
    const ct = new AbortController();
    authFetch(chatsApi + '/chats/personal/' + userId, {
      signal: ct.signal,
    }).then((data) => {
      const chatId = data;
      navigate(`/chats?id=${chatId}`);
    });
  };

  return (
    <Button size='small' onClick={() => handleChatClick()}>
      <Chat></Chat>
    </Button>
  );
}
