import React, { useState } from "react";
import {
  MapContainer, 
  TileLayer, 
  useMapEvents } from "react-leaflet";

export function MapDefaultZoom()
{
  return 11;
}
export default function OSMap(props) {
  const [coords, setCoords] = useState({
    latitude: props.coords !== undefined ? props.coords.latitude : "59.952122",
    longitude:
      props.coords !== undefined ? props.coords.longitude : "30.315465",
  });

  function CoordsOnClickGetter() {
    const map = useMapEvents({
      click: (e) => {
        if (props.onClickCoordsGetter !== undefined)
          props.onClickCoordsGetter({
            latitude: e.latlng.lat,
            longitude: e.latlng.lng,
          });

        //map.locate()
      },
      // locationfound: (location) => {

      //     console.log(location.latitude + ' '+ location.longitude);
      // },
    });
    return null;
  }

  return (
    <div id="map">
      <link rel='stylesheet' href='//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css'/>
      <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css"/>
      <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"/>
      <MapContainer
        center={[coords.latitude, coords.longitude]}
        zoom={props.zoom ?? MapDefaultZoom()}
        maxZoom={18}
        attributionControl={false}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        style={props.styles}
      >
        <CoordsOnClickGetter />
        <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
        {props.children}
      </MapContainer>
    </div>
  );
}
