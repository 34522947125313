import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import useAuthFetch from '../../Hooks/useAuthFetch';
import OSMap from '../Maps/OSMap';
import PlaceMarker from '../Maps/PlaceMarker';
import PlaceCard from './PlaceCard';
import AddPlace from './AddPlace';
import { LinearProgress, Grid, Typography, Grow } from '@mui/material';

export default function Place(props) {
  //const currentUser = UserContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [place, setPlace] = useState();
  const [edit, setEdit] = useState(false);
  const id = useParams().id;

  const authFetch = useAuthFetch();

  const fetchData = (ct) => {
    authFetch(baseApiUrl() + '/place/' + id, { signal: ct.signal }).then(
      (data) => {
        setPlace({
          ...data.place,
          canEdit: data.permissions.canEdit,
          canDelete: data.permissions.canDelete,
        });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    let controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, [id]);

  // const getAddress = (lat, lon) => {
  //   var url =
  //     "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +
  //     lat +
  //     "&lon=" +
  //     lon +
  //     "&addressdetails=1";
  //   fetch(url, {
  //     method: "GET",
  //     mode: "cors",
  //     headers: {
  //       "Access-Control-Allow-Origin": "https://o2cj2q.csb.app",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data !== undefined) {
  //         //setInputElVal(refAddress.current, data.display_name);
  //       }
  //     });
  // };

  // const onClickCoordsGetter = (location) => {
  //   if (
  //     location !== undefined &&
  //     location.latitude !== undefined &&
  //     location.longitude !== undefined
  //   ) {
  //     getAddress(location.latitude, location.longitude);
  //     // setInputElVal(refLatitude.current, location.latitude);
  //     // setInputElVal(refLongitude.current, location.longitude);
  //   }
  // };

  const onShowPlaсes = (needShow) => {
    if (needShow === true) {
      setEdit(false);
      fetchData(new AbortController());
    }
  };

  const onEdit = () => {
    setEdit(true);
  };

  return (
    <>
      {edit ? (
        <Grid container spacing={4} justifyContent='center'>
          <Grow in={edit}>
            <Grid item xs={12} md={8} lg={6}>
              <AddPlace
                places={[place]}
                showPlaces={onShowPlaсes}
                update={true}
                place={place}
                onBackClick={() => {
                  setEdit(false);
                }}
              />
            </Grid>
          </Grow>
        </Grid>
      ) : (
        <>
          {loading ? (
            <LinearProgress />
          ) : (
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <PlaceCard
                  key={place.id}
                  place={place}
                  permissions={{
                    canEdit: place.canEdit,
                    canDelete: place.canDelete,
                  }}
                  hideBtnMore={true}
                  showLatLngFieldes={true}
                  onEdit={() => {
                    setEdit(true);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <OSMap
                  coords={{
                    latitude: place?.latitude ?? '59.952122',
                    longitude: place?.longitude ?? '30.315465',
                  }}
                  zoom={15}
                  styles={{ height: '600px' }}
                  //onClickCoordsGetter={onClickCoordsGetter}
                >
                  <PlaceMarker
                    key={'PlaceMarker_' + place.id}
                    position={[place.latitude, place.longitude]}
                    iconType={place.gameTypeId}
                  >
                    <Typography>{place.name}</Typography>
                  </PlaceMarker>
                </OSMap>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
