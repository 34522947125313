import React, { useState, useEffect, useContext } from 'react';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import AddPlace from './AddPlace';
import useAuthFetch from '../../Hooks/useAuthFetch';
import PlaceFilter from './PlaceFilter';
import PlaceCard from './PlaceCard';
import PlaceMapCard from './PlaceMapCard';
import {
  LinearProgress,
  Tab,
  Tabs,
  Button,
  Grid,
  Box,
  Paper,
  Grow,
  Typography,
} from '@mui/material';
import {
  ViewAgendaOutlined,
  ExploreOutlined,
  AddOutlined,
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import OSMap, { MapDefaultZoom } from '../Maps/OSMap';
import MapEditCtrls from '../Maps/MapEditCtrls';
import PlaceMarker from '../Maps/PlaceMarker';
import { Stack } from '@mui/system';
import { Search, SearchIconWrapper, StyledInputBase } from '../Search/Search';
import GotoLoginDialog from '../Shared/GotoLoginDialog';
import { UserContext } from '../../Kernel/UserContext';

let useGeozone = false; // вынес сюда, так как, если сначал выбрать показывать геозону
// а потом ее нарисовать, она не применяется при useState.
function Places({ filterSettings, selectionMode, onSelectClick }) {
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [status, setStatus] = useState('loading');
  const [openLogin, setOpenLogin] = useState(false);
  const user = useContext(UserContext);

  const [filter, setFilter] = useState(
    filterSettings && filterSettings.preselectedGameType
      ? { gameType: filterSettings.preselectedGameType }
      : {}
  );
  const [tab, setTab] = useState(localStorage.placesPageTab ?? 'map');

  const authFetch = useAuthFetch();

  const [coords, setCoords] = useState({
    latitude: '59.952122',
    longitude: '30.315465',
  });
  const [mapZoom, setMapZoom] = useState(MapDefaultZoom);
  const [geozone, setGeozone] = useState({});

  const fetchData = (ct) => {
    setStatus('loading');
    const searchParams = new URLSearchParams(filter).toString();

    const path =
      baseApiUrl() +
      '/places' +
      (searchParams.length > 0 ? '?' + searchParams : '');
    authFetch(path, { signal: ct.signal }).then((data) => {
      setPlaces(data);
      setStatus('show');
    });
  };

  const onShowPlaces = (needShow) => {
    if (needShow === true) {
      setStatus('show');
      fetchData(new AbortController());
    }
  };

  const onShowPlaceOnMap = (place) => {
    if (place !== undefined) {
      setCoords({ latitude: place.latitude, longitude: place.longitude });
      setMapZoom(15);
      setTab('map');
    }
  };

  const onChangeGeozone = (gz) => {
    setGeozone(gz);
    console.log('onChangeGeozone', gz);
    console.log('useGeozone', useGeozone);
    if (
      useGeozone &&
      gz.latitudeFrom !== undefined &&
      gz.latitudeTo !== undefined &&
      gz.longitudeFrom !== undefined &&
      gz.longitudeTo !== undefined
    ) {
      setFilter({ ...filter, ...gz });
    } else {
      const items = { ...filter };
      delete items['latitudeFrom'];
      delete items['latitudeTo'];
      delete items['longitudeFrom'];
      delete items['longitudeTo'];
      setFilter(items);
    }
  };
  const onUseGeozone = (useGz) => {
    console.log('onUseGeozone:useGz', useGz);
    useGeozone = useGz;
    console.log('onUseGeozone:useGeozone', useGeozone);
    onChangeGeozone(geozone, useGz);
  };

  useEffect(() => {
    let controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, [filter, tab]);

  const onCreateClicked = () => {
    if (!user.id) {
      setOpenLogin(true);
    } else {
      setStatus('create');
    }
  };

  return (
    <Grid container spacing={4} justifyContent='center'>
      {status === 'create' ? (
        <Grow in={status === 'create'}>
          <Grid item xs={12} md={8} lg={6}>
            <AddPlace
              places={places}
              showPlaces={onShowPlaces}
              update={status === 'update'}
              place={status === 'update' ? selectedPlace : {}}
              onBackClick={() => {
                setStatus('show');
              }}
              selectionMode={selectionMode ?? false}
              onSelectClick={onSelectClick}
              preselectedGameType={
                filterSettings && filterSettings.preselectedGameType
                  ? filterSettings.preselectedGameType
                  : 0
              }
            />{' '}
          </Grid>
        </Grow>
      ) : (
        <>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item xs={12} md={10}>
              <Box
                component='form'
                sx={{
                  mt: 2,
                  border: 1,
                  borderColor: '#031E49',
                  borderRadius: 2,
                }}
                noValidate
                autoComplete='false'
              >
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder='Название локации…'
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => {
                      setFilter({ ...filter, name: e.target.value });
                    }}
                    value={filter.name ?? ''}
                  />
                </Search>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Stack spacing={2}>
              <PlaceFilter
                filter={filter}
                setFilter={setFilter}
                onUseGeozone={onUseGeozone}
                settings={filterSettings}
              />
              <Button
                size='large'
                variant='contained'
                color='success'
                onClick={onCreateClicked}
                fullWidth
                disabled={status === 'loading'}
              >
                <AddOutlined sx={{ mr: 1 }} /> Новая локация
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Paper elevation={3}>
              <Tabs
                value={tab}
                variant='fullWidth'
                onChange={(event, index) => {
                  setTab(index);
                  localStorage.placesPageTab = index;
                }}
              >
                <Tab
                  icon={<ExploreOutlined />}
                  iconPosition='start'
                  label='Карта'
                  value='map'
                />
                <Tab
                  icon={<ViewAgendaOutlined />}
                  iconPosition='start'
                  label='Список'
                  value='list'
                />
              </Tabs>
              {status === 'loading' && <LinearProgress />}
              {tab === 'map' && status !== 'create' && (
                <OSMap
                  coords={coords}
                  zoom={mapZoom}
                  styles={{ height: '600px' }}
                >
                  {filterSettings && !filterSettings.hideGeoZone && (
                    <MapEditCtrls
                      geozone={geozone}
                      onChangeGeozone={onChangeGeozone}
                    />
                  )}
                  {places.length > 0 &&
                    places.map((group, index) => (
                      <PlaceMarker
                        key={index}
                        position={[group.place.latitude, group.place.longitude]}
                        iconType={group.place.gameTypeId}
                      >
                        <PlaceMapCard
                          key={index}
                          place={group.place}
                          selectionMode={selectionMode ?? false}
                          onSelectClick={onSelectClick}
                        />
                      </PlaceMarker>
                    ))}
                </OSMap>
              )}
              {status === 'show' &&
                tab === 'list' &&
                (places.length > 0 ? (
                  <Grid container spacing={2} p={2}>
                    {places.map((p) => (
                      <Grid item xs={12} key={p.place.id}>
                        <PlaceCard
                          key={p.place.id}
                          place={p.place}
                          permissions={p.permissions}
                          onShowPlaceOnMap={onShowPlaceOnMap}
                          selectionMode={selectionMode ?? false}
                          onSelectClick={onSelectClick}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography
                    margin={3}
                    paddingBottom={2}
                    variant='h6'
                    textAlign={'center'}
                  >
                    Локации не найдены
                  </Typography>
                ))}
            </Paper>
          </Grid>

          <GotoLoginDialog
            actionName={'Добавление локации'}
            open={openLogin}
            setOpen={setOpenLogin}
          />
        </>
      )}
    </Grid>
  );
}

export default Places;
