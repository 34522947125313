import React from 'react';
import { renderToString } from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import GameTypeIcon from '../Shared/GameTypeIcon';
import { Box } from '@mui/material';

const markerHtmlStyles = `
  background-color: transparent;
  width: 2.6rem;
  height: 2.6rem;
  align-items: center;
  display: block;
  left: 1.3rem;
  top: -1.3rem;
  position: relative;
  border-radius: 2.6rem 2.6rem 2.6rem 0;
  padding: 0.25rem;
  background-image: radial-gradient(circle at left bottom, rgb(255,255,255,1), rgba(255,255,255,0.85));   
  `;

function getIcon(type) {
  const baseIconSize = [35, 35];
  const baseIconAnchor = [17, 17];
  const typeIcon = (
    <div
      style={{
        border: '2px solid',
        borderRadius: '2.6rem 2.6rem 2.6rem 0',
        alignItems: 'center',
        textAlign: 'center',
        height: '90%',
        width: '90%',
        lineHeight: '2.3rem',
        //padding: type === 5 || type === 6 ? "0.25rem" : "0",
        display: 'block',
      }}
    >
      <GameTypeIcon fontSize='normal' gameTypeId={type}></GameTypeIcon>
    </div>
  );
  return L.divIcon({
    className: 'custom icon',
    html:
      "<div style='" +
      markerHtmlStyles +
      "'>" +
      renderToString(typeIcon) +
      '</div>',
    iconSize: baseIconSize,
    iconAnchor: baseIconAnchor,
  });
}

function countIcons(count) {
  const showRawValue = count > 1 && count < 100;
  const value = showRawValue ? count : '99+';
  const size = showRawValue ? '1.8rem' : '1.2rem';

  return (
    <div
      style={{
        border: '2px solid',
        borderRadius: '2.6rem 2.6rem 2.6rem 0',
        fontSize: size,
        alignItems: 'center',
        textAlign: 'center',
        height: '90%',
        width: '90%',
        lineHeight: '2.3rem',
      }}
    >
      <b>{value}</b>
    </div>
  );
}

function getTypesCountIcon(count) {
  const baseIconSize = [35, 35];
  const baseIconAnchor = [17, 17];
  const typeIcon = countIcons(count);
  return L.divIcon({
    className: 'custom icon',
    html:
      "<div style='" +
      markerHtmlStyles +
      "'>" +
      renderToString(typeIcon) +
      '</div>',
    iconSize: baseIconSize,
    iconAnchor: baseIconAnchor,
  });
}

export default function PlaceMarker(props) {
  return (
    <Marker
      position={props.position}
      icon={
        props.typesCount > 1
          ? getTypesCountIcon(props.typesCount)
          : getIcon(props.iconType)
      }
    >
      <Popup>
        <Box
          sx={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            maxHeight: '400px',
            width: '305px',
            '&::-webkit-scrollbar': {
              width: 4,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'ligthgray',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'gray',
              borderRadius: 2,
            },
          }}
        >
          {props.children}
        </Box>
      </Popup>
    </Marker>
  );
}
