export function GetTodayString() {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();

  return `${y}-${m <= 9 ? '0' + m : m}-${d <= 9 ? '0' + d : d}`;
}

export function TimeStringToMs(time) {
  const components = time.split(':');
  return (components[0] * 60 * 60 + components[1] * 60 + components[2]) * 1000;
}

export function ToLocalIsoString(date) {
  const d = new Date(date);
  const z = d.getTimezoneOffset() * 60 * 1000;
  const dateLocal = new Date(d - z);
  return dateLocal.toISOString().split('.')[0];
}
