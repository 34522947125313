import { Close } from '@mui/icons-material';
import { Alert, Collapse, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

export default function AuthAlert({ text, severity, timestamp }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(String(text).length > 0);
  }, [timestamp]);

  return (
    <Collapse in={open}>
      <Alert
        severity={severity}
        action={
          <IconButton
            color='inherit'
            size='small'
            onClick={() => setOpen(false)}
          >
            <Close fontSize='inherit' />
          </IconButton>
        }
      >
        {text}
      </Alert>
    </Collapse>
  );
}
