import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Kernel/AuthContext';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export default function useHubConnection(api, hub) {
  const { token } = useContext(AuthContext);

  const defaultObject = { on: () => {}, off: () => {}, invoke: () => {} };
  const [hubConnection, setHubConnection] = useState(defaultObject);

  useEffect(() => {
    if (api !== '' && token !== '') {
      setHubConnection(
        new HubConnectionBuilder()
          .withUrl(`${api}/${hub}`, {
            accessTokenFactory: () => {
              return token;
            },
          })
          .withAutomaticReconnect([0, 2000, 3000, 5000, 10000, 30000])
          .configureLogging(LogLevel.Debug)
          .build()
      );
    }
  }, [api, token]);

  useEffect(() => {
    if (hubConnection) {
      if (hubConnection.state === 'Disconnected') {
        hubConnection.start();
      }

      return () => {
        if (hubConnection.state === 'Connected') {
          hubConnection.stop();
        }
      };
    }
  }, [hubConnection]);

  return hubConnection;
}
