import React from 'react';
import { Avatar, Box, Card, CardActions, IconButton } from '@mui/material';
import { HasAvatar } from '../../../Kernel/UserHelper';
import { Delete, Upload } from '@mui/icons-material';

import { baseApiUrl } from '../../../Kernel/RestApiHelper';
import useAuthFetch from '../../../Hooks/useAuthFetch';

export default function AvatarUploader(props) {
  const { user, refreshUser } = { ...props };

  const avatarPath = baseApiUrl() + '/user/' + user.id + '/avatar';

  const authFetch = useAuthFetch();

  const handleUploadAvatar = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = [...e.target.files];

      const data = new FormData();
      files.forEach((file, i) => {
        data.append('files', file, file.name);
      });

      authFetch(avatarPath, {
        method: 'POST',
        body: data,
        skipParsing: true,
      }).then(() => refreshUser());
    }
  };

  const handleDeleteAvatar = (e) => {
    authFetch(avatarPath, {
      method: 'DELETE',
    }).then(() => refreshUser());
  };

  return (
    <Card>
      <Avatar
        sx={{ width: '100%', height: '100%' }}
        variant='rounded'
        src={
          HasAvatar(user)
            ? baseApiUrl() + '/files/' + user.avatarFull
            : undefined
        }
      ></Avatar>
      <CardActions>
        <Box sx={{ flex: 1 }}></Box>
        <IconButton component='label' size='large' color='primary'>
          <Upload></Upload>
          <input
            hidden
            accept='image/*'
            type='file'
            onChange={handleUploadAvatar}
          />
        </IconButton>
        <IconButton
          onClick={handleDeleteAvatar}
          size='large'
          color='primary'
          disabled={!HasAvatar(user)}
        >
          <Delete></Delete>
        </IconButton>
        <Box sx={{ flex: 1 }}></Box>
      </CardActions>
    </Card>
  );
}
