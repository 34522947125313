import React from 'react';
import { InputLabel, Select, FormControl } from '@mui/material';

export default function FilterSelector(props) {
  const id = props.tag + '-select';
  const labelId = props.tag + '-select-label';
  const labelVal = props.label ?? 'Label';

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId} size='small' required={props.required ?? false}>
        {labelVal}
      </InputLabel>
      <Select
        labelId={labelId}
        id={id}
        label={labelVal}
        value={props.val ?? ''}
        size='small'
        name={props.name ?? ''}
        required={props.required ?? false}
        onChange={props.handleChange}
        disabled={props.disabled ?? false}
      >
        {props.children}
      </Select>
    </FormControl>
  );
}
