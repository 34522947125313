export const PagesFreeAccess = [
  '/login',
  '/register',
  '/reset_password',
  '/confirm_email',
  '/games',
  '/game',
  '/places',
  '/place/',
];
