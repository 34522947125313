import { Avatar, Button } from '@mui/material';
import React from 'react';
import { GetUserName, HasAvatar } from '../../Kernel/UserHelper';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import { AccountCircle } from '@mui/icons-material';
import UserOnlineBadge from '../Shared/UserOnlineBadge';

export default function UserLink({ user }) {
  return (
    <Button
      size='small'
      startIcon={
        <UserOnlineBadge userId={user.id}>
          {HasAvatar(user) ? (
            <Avatar
              sx={{ marginLeft: 1, width: 30, height: 30 }}
              src={baseApiUrl() + '/files/' + user.avatarSmall}
            ></Avatar>
          ) : (
            <AccountCircle
              sx={{ marginLeft: 1, width: 30, height: 30 }}
              fontSize='large'
              color='disabled'
            />
          )}
        </UserOnlineBadge>
      }
    >
      {GetUserName(user)}
    </Button>
  );
}
