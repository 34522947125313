import React, { useEffect, useState } from 'react';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import useAuthFetch from '../../Hooks/useAuthFetch';
import { Grid, Select, MenuItem, Skeleton } from '@mui/material';
import FilterSelector from './FilterSelector';

export default function AddressFilter({ filter, setFilter }) {
  const countryLabel = 'Страна';
  const regionLabel = 'Регион';
  const cityLabel = 'Город';
  //const districtLabel = 'Районы';

  const [countries, setCountries] = useState([{ id: '', name: 'Не выбрана' }]);
  const [loadingCountries, setLoadingCountries] = useState(true);

  const [regions, setRegions] = useState([{ id: '', name: 'Не выбран' }]);
  const [loadingRegions, setLoadingRegions] = useState(true);

  const [cities, setCities] = useState([{ id: '', name: 'Не выбран' }]);
  const [loadingCities, setLoadingCities] = useState(true);

  const authFetch = useAuthFetch();

  const handleChange = (e) => {
    if (e.target.name === 'countryId') {
      delete filter.regionId;
      delete filter.cityId;
    }

    if (e.target.name === 'regionId') {
      delete filter.cityId;
    }
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const ct = new AbortController();

    authFetch(baseApiUrl() + '/addresses/countries', {
      signal: ct.signal,
    }).then((data) => {
      setCountries(data);
      setLoadingCountries(false);
    });

    return () => {
      ct.abort();
    };
  }, []);

  useEffect(() => {
    const ct = new AbortController();
    let searchParams = '';

    if (filter.countryId && filter.countryId > 0) {
      searchParams = new URLSearchParams({
        countryId: filter.countryId,
      }).toString();
    }

    const path =
      baseApiUrl() +
      '/addresses/regions' +
      (searchParams.length > 0 ? '?' + searchParams : '');

    setLoadingRegions(true);
    authFetch(path, { signal: ct.signal }).then((data) => {
      setRegions(data);
      setLoadingRegions(false);
    });

    return () => {
      ct.abort();
    };
  }, [filter.countryId]);

  useEffect(() => {
    const ct = new AbortController();
    let searchParams = '';

    let cityFilter = {};
    if (filter.countryId && filter.countryId > 0) {
      cityFilter = { ...cityFilter, countryId: filter.countryId };
    }

    if (filter.regionId && filter.regionId > 0) {
      cityFilter = { ...cityFilter, regionId: filter.regionId };
    }

    searchParams = new URLSearchParams(cityFilter).toString();
    const path =
      baseApiUrl() +
      '/addresses/cities' +
      (searchParams.length > 0 ? '?' + searchParams : '');

    setLoadingCities(true);
    authFetch(path, { signal: ct.signal }).then((data) => {
      setCities(data);
      setLoadingCities(false);
    });

    return () => {
      ct.abort();
    };
  }, [filter.countryId, filter.regionId]);

  const createMenuItems = (items) => {
    return items.map((city) => (
      <MenuItem key={city.id} value={city.id}>
        {city.name}
      </MenuItem>
    ));
  };

  return (
    <>
      <Grid item xs={12}>
        {loadingCountries ? (
          <Skeleton variant='rounded' animation='wave' width={'100%'}>
            <Select size='small' value={''}></Select>
          </Skeleton>
        ) : (
          <FilterSelector
            tag='country'
            label={countryLabel}
            val={filter.countryId ?? ''}
            name='countryId'
            handleChange={handleChange}
          >
            <MenuItem value={''}>
              <em>Не выбрана</em>
            </MenuItem>
            {createMenuItems(countries)}
          </FilterSelector>
        )}
      </Grid>
      <Grid item xs={12}>
        {loadingRegions ? (
          <Skeleton variant='rounded' animation='wave' width={'100%'}>
            <Select size='small' value={''}></Select>
          </Skeleton>
        ) : (
          <FilterSelector
            tag='region'
            label={regionLabel}
            val={filter.regionId ?? ''}
            name='regionId' // Пока по аналогии с поиском, только по адресу
            handleChange={handleChange}
          >
            <MenuItem value={''}>
              <em>Не выбран</em>
            </MenuItem>
            {createMenuItems(regions)}
          </FilterSelector>
        )}
      </Grid>
      <Grid item xs={12}>
        {loadingCities ? (
          <Skeleton variant='rounded' animation='wave' width={'100%'}>
            <Select size='small' value={''}></Select>
          </Skeleton>
        ) : (
          <FilterSelector
            tag='city'
            label={cityLabel}
            val={filter.cityId ?? ''}
            name='cityId' // Пока по аналогии с поиском, только по адресу
            handleChange={handleChange}
          >
            <MenuItem value={''}>
              <em>Не выбран</em>
            </MenuItem>
            {createMenuItems(cities)}
          </FilterSelector>
        )}
      </Grid>
    </>
  );
}
