import { useState } from 'react';
import { baseApiUrl } from '../Kernel/RestApiHelper';
import { PagesFreeAccess } from '../Kernel/FreePages';

export default function useToken(updateUserInfo) {
  const [token, setToken] = useState('');

  let auth = token;
  let refreshing = false;

  const refreshAuthToken = async (init) => {
    if (!refreshing) {
      refreshing = true;

      init = init || {};

      const response = await fetch(baseApiUrl() + '/auth/refresh', {
        ...init,
        method: 'POST',
      }).then((r) => {
        if (r.status === 401) return null;
        else return r.json();
      });

      let result = token;
      if (!response) {
        const page = window.location.pathname;
        if (PagesFreeAccess.every((p) => !page.startsWith(p))) {
          const returnUrl = new URLSearchParams({
            returnUrl: window.location.pathname,
          }).toString();
          window.location = '/login?' + returnUrl;
        }
        updateUserInfo({
          firstName: '',
          middleName: '',
          lastName: '',
        });
      } else {
        setToken(response.authToken);
        auth = response.authToken;
        updateUserInfo(response.user);
        result = response.authToken;
      }

      refreshing = false;
      return result;
    } else {
      let count = 0;
      while (refreshing && count < 100) {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 25);
        });

        count++;
      }
      return auth;
    }
  };

  return {
    authToken: token,
    setAuthToken: setToken,
    refreshAuthToken,
  };
}
