import './App.css';
import Header from './Components/Header';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import ResetPassword from './Components/Auth/ResetPassword';
import Games from './Components/Games/Games';
import Game from './Components/Games/Game';
import Places from './Components/Places/Places';
import Place from './Components/Places/Place';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import UserSettings from './Components/Users/UserSettings';
import useToken from './Hooks/useToken';
import ConfirmEmail from './Components/Users/ConfirmEmail';
import Chats from './Components/Chats/Chats';
import { baseApiUrl } from './Kernel/RestApiHelper';
import Notifications from './Components/Shared/Notifications';
import CustomContextProvider from './Kernel/CustomContextProvider';

function App() {
  const [topMenuHub, setTopMenuHub] = useState({});

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
  });
  const [showMenu, setShowMenu] = useState(userInfo.emailVerified);
  const [chatsApiPath, setChatsApiPath] = useState('');
  const [notification, setNotification] = useState({
    text: '',
    severity: 'info',
  });

  const showNotification = (text, severity) => {
    setNotification({ text, severity, timestamp: Date.now() });
  };

  const updateUserInfo = (info) => {
    localStorage.setItem('playmaker_current_user', JSON.stringify(info));
    setUserInfo(info);

    if (
      info.firstName !== '' &&
      !info.emailVerified &&
      !window.location.href.includes('/confirm_email')
    ) {
      window.location = '/confirm_email';
    }
  };

  const { authToken, setAuthToken, refreshAuthToken } =
    useToken(updateUserInfo);

  const updateChatsApiPath = () => {
    fetch(baseApiUrl() + '/common/chatsApi')
      .then((response) => response.text())
      .then((data) => {
        setChatsApiPath(data);
      });
  };

  useEffect(() => {
    let controller = new AbortController();
    if (authToken === '') {
      refreshAuthToken(controller);
    }

    updateChatsApiPath();

    const user = localStorage.getItem('playmaker_current_user');
    if (user && user !== null && user !== 'null' && user !== 'undefined') {
      setUserInfo(JSON.parse(user));
    }

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setShowMenu(userInfo.emailVerified);
  }, [userInfo.emailVerified]);

  return (
    <Router>
      <CustomContextProvider
        topMenuHub={topMenuHub}
        showNotification={showNotification}
        authToken={authToken}
        refreshAuthToken={refreshAuthToken}
        chatsApiPath={chatsApiPath}
        userInfo={userInfo}
      >
        <Header
          setUser={setUserInfo}
          setToken={setAuthToken}
          setTopMenuHub={setTopMenuHub}
        />
        <Container maxWidth='false' fluid={'true'} sx={{ marginBottom: 2 }}>
          <Routes>
            <Route
              path='/login'
              element={
                <Login setUser={updateUserInfo} setToken={setAuthToken} />
              }
            />
            <Route path='/register' element={<Register />} />
            <Route path='/reset_password' element={<ResetPassword />} />
            <Route path='/games' element={<Games />} />
            <Route path='/game/:id' element={<Game />} />
            <Route
              path='/places'
              element={<Places filterSettings={{ hideGeoZone: true }} />}
            />
            <Route path='/place/:id' element={<Place />} />
            <Route path='*' element={<Navigate to='/games' />} />
            <Route
              path='/settings'
              element={<UserSettings setUserContext={updateUserInfo} />}
            />
            <Route
              path='/confirm_email'
              element={<ConfirmEmail enableMenu={() => setShowMenu(true)} />}
            />
            <Route path='/chats' element={<Chats />} />
          </Routes>
        </Container>
      </CustomContextProvider>
      <Notifications
        severity={notification.severity}
        text={notification.text}
        timestamp={notification.timestamp}
      ></Notifications>
    </Router>
  );
}

export default App;
