import React, { useState, useEffect } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';

import { baseApiUrl } from '../../../Kernel/RestApiHelper';
import useAuthFetch from '../../../Hooks/useAuthFetch';

export default function UserDataChanger(props) {
  const { currentUser, refreshUser } = { ...props };
  const [user, setUser] = useState(currentUser);

  const [saveDisabled, setSaveDisabled] = useState(true);

  const authFetch = useAuthFetch();

  const sameUserData = () => {
    return (
      user.firstName === currentUser.firstName &&
      user.middleName === currentUser.middleName &&
      user.lastName === currentUser.lastName
    );
  };

  const handleText = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const updateUser = () => {
    setSaveDisabled(true);
    authFetch(baseApiUrl() + '/user/' + currentUser.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(user),
    }).then(() => {
      refreshUser();
    });
  };

  useEffect(() => {
    setSaveDisabled(sameUserData);
  }, [user]);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  return (
    <Box
      component={'form'}
      onSubmit={(e) => {
        e.preventDefault();
        updateUser();
      }}
    >
      <Stack spacing={2}>
        <TextField
          label='Имя'
          required
          value={user.firstName}
          name='firstName'
          onChange={handleText}
        />
        <TextField
          label='Отчетство'
          value={user.middleName}
          name='middleName'
          onChange={handleText}
        />
        <TextField
          label='Фамилия'
          value={user.lastName}
          name='lastName'
          onChange={handleText}
        />
        <Button type='submit' disabled={saveDisabled} variant='contained'>
          Сохранить изменения
        </Button>
      </Stack>
    </Box>
  );
}
