import { Delete, Edit, CurrencyRuble, CopyAll } from '@mui/icons-material';
import GameTypeIcon from '../Shared/GameTypeIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from '@mui/material';
import React, { useState, useEffect } from 'react';

import { baseApiUrl } from '../../Kernel/RestApiHelper';
import useAuthFetch from '../../Hooks/useAuthFetch';

import { useNavigate } from 'react-router-dom';
import { GetAddressString } from '../../Kernel/AddressHelper';

export default function PlaceCard(props) {
  const [openDelete, setOpenDelete] = useState(false);
  const place = props.place;
  const permissions = props.permissions;
  const hideBtnMore = props.hideBtnMore;

  const authFetch = useAuthFetch();

  const navigate = useNavigate();

  useEffect(() => {
    let controller = new AbortController();

    return () => {
      controller.abort();
    };
  }, [place]);

  const navigateToPlace = (placeId) => {
    console.log(placeId);
    navigate('/place/' + placeId);
  };

  const deletePlace = (place) => {
    authFetch(baseApiUrl() + '/place/' + place.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(place),
    }).then(() => navigate('/places'));
  };

  const btnsMenu = (
    <>
      {!hideBtnMore && (
        <>
          {props.selectionMode ? (
            <Button onClick={() => props.onSelectClick(place)}>Выбрать</Button>
          ) : (
            <Button onClick={() => navigateToPlace(place.id)}>Подробнее</Button>
          )}
          <Button
            onClick={(e) => {
              props.onShowPlaceOnMap(place);
            }}
          >
            На карте
          </Button>
        </>
      )}
      {hideBtnMore && permissions?.canEdit && (
        <IconButton
          size='medium'
          onClick={() => {
            props.onEdit();
          }}
        >
          <Edit fontSize='large' />
        </IconButton>
      )}
      {hideBtnMore && permissions?.canDelete && (
        <IconButton size='medium' onClick={() => setOpenDelete(true)}>
          <Delete fontSize='large' />
        </IconButton>
      )}
    </>
  );

  return (
    <Card>
      <Grid container>
        <Grid item xs={12} lg={3}>
          <Grid
            item
            xs={12}
            container
            spacing={0.5}
            alignItems='center'
            padding={2}
          >
            <Grid item xs='auto'>
              <GameTypeIcon
                fontSize='large'
                gameTypeId={place.gameTypeId}
              ></GameTypeIcon>
            </Grid>
            <Grid item xs>
              <Typography
                variant='subtitle1'
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {place.name}
              </Typography>
              <Typography variant='subtitle2' color={'#AAA'}>
                {place.gameTypeName}
              </Typography>
            </Grid>
            <Grid item>
              <CardActions sx={{ display: { lg: 'none', xs: 'block' } }}>
                {btnsMenu}
              </CardActions>
            </Grid>
          </Grid>
          <Grid item lg='auto'>
            <CardActions sx={{ display: { lg: 'block', xs: 'none' }, pl: 1.5 }}>
              {btnsMenu}
            </CardActions>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={9}>
          <CardContent>
            <Grid container columnSpacing={3} rowSpacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant='body2'
                  style={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  <b>Описание:</b> {place.description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={0.5} alignItems='center'>
                  {/* <Place color='action' /> */}
                  <Typography variant='body2'>
                    <b>Адрес:</b> {GetAddressString(place.address)}
                  </Typography>
                </Stack>
              </Grid>
              {place.isPaid && (
                <Grid item>
                  <CurrencyRuble />
                </Grid>
              )}
              {props.showLatLngFieldes && (
                <>
                  <Grid item>
                    <Stack direction={'row'} spacing={0.5} alignItems='center'>
                      <Typography variant='body2'>
                        <b>Широта:</b> {place.latitude}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction={'row'} spacing={0.5} alignItems='center'>
                      <Typography variant='body2'>
                        <b>Долгота:</b> {place.longitude}
                      </Typography>
                    </Stack>
                  </Grid>
                  <IconButton
                    size='small'
                    onClick={() =>
                      navigator.clipboard.writeText(
                        place.latitude + ', ' + place.longitude
                      )
                    }
                  >
                    <Tooltip title='Скопировать координаты'>
                      <CopyAll fontSize='small' />
                    </Tooltip>
                  </IconButton>
                </>
              )}
            </Grid>
          </CardContent>
        </Grid>
        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
          <DialogTitle>Удалить?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Это действие нельзя будет отменить.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDelete(false)} autoFocus>
              Назад
            </Button>
            <Box sx={{ flex: 1 }}></Box>
            <Button
              variant='contained'
              color='error'
              onClick={() => {
                setOpenDelete(false);
                deletePlace(place);
              }}
            >
              Удалить
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Card>
  );
}
