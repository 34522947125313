import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const removeNode = (selector) => {
  const node = document.querySelector(selector);
  const parent = node.parentNode;
  parent.removeChild(node);
};

function setPrices() {
  var radios = document.querySelectorAll('input[type=radio][name="prices_period_radio"]');
  function changeHandler(event) {
    const base = document.getElementById("prices__amounts__base");
    const prof = document.getElementById("prices__amounts__prof");
    const champ = document.getElementById("prices__amounts__champion");

    switch (this.value)
    {
      case '1':
        base.innerHTML='180';
        prof.innerHTML='390';
        champ.innerHTML='1000';
        break;
      case '2':
        base.innerHTML='173';
        prof.innerHTML='380';
        champ.innerHTML='987';
        break;
      case '3':
        base.innerHTML='162';
        prof.innerHTML='366';
        champ.innerHTML='970';
        break;
      case '4':
        base.innerHTML='150';
        prof.innerHTML='350';
        champ.innerHTML='950';
        break;
      default: console.log('Not found');
    }
  }
  Array.prototype.forEach.call(radios, function(radio) {
    radio.addEventListener('change', changeHandler);
  });
}

if (window.location.pathname !== '/') {
  removeNode('#info');
  removeNode('#footer');

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  const user = localStorage.getItem('playmaker_current_user');
  if (user && user !== null && user !== 'null' && user !== 'undefined') {
    const info = JSON.parse(user);
    if (info.firstName !== '') {
      const logout = document.createElement('a');
      logout.setAttribute('href', '/');
      logout.setAttribute(
        'onClick',
        "fetch('/api/auth/logout', {method: 'POST',});localStorage.removeItem('playmaker_current_user');"
      );
      logout.innerHTML = 'Выйти';

      const rightMenu = document.querySelector('#info__right-menu');
      rightMenu.replaceChildren(logout);

      const button = document.querySelector('#big_register_button');
      button.innerHTML = 'К играм!';
      button.setAttribute('href', '/games');
    }
  }
}

setPrices();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
