import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import PasswordInput from './PasswordInput';
import {
  Typography,
  FormControl,
  Button,
  Grid,
  Box,
  TextField,
  Stack,
  LinearProgress,
} from '@mui/material';
import AuthAlert from './AuthAlert';

export default function Login(props) {
  const [data, setData] = useState({});

  const navigate = useNavigate();
  const returnUrl =
    window.location.search.length > 0
      ? Object.fromEntries(new URLSearchParams(window.location.search))
          .returnUrl
      : '/';

  const [processing, setProcessing] = useState(false);
  const [info, setInfo] = useState({
    text: '',
    severity: 'info',
  });
  const notify = (text, severity) => {
    setInfo({ text, severity, timestamp: Date.now() });
  };

  const login = () => {
    setProcessing(true);
    fetch(baseApiUrl() + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        setProcessing(false);
        if (!response.ok) {
          const message = await response.text();
          throw new Error(message);
        }

        return response.json();
      })
      .then((token) => {
        props.setToken(token.authToken);
        props.setUser(token.user);
        navigate(returnUrl);
      })
      .catch((err) => {
        const message = err.message;
        notify(message, 'error');
      });
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Grid container justifyContent='center' marginTop={7}>
        <Grid
          item
          xs={12}
          md={8}
          mt={1}
          textAlign='center'
          justifyContent='center'
          container
          spacing={5}
        >
          <Grid item xs={12} md={10}>
            <Typography variant='h3'>Присоединяйся к команде</Typography>
            <Typography>Заходи и выбери свою по душе</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={8} lg={5}>
            <Box
              component='form'
              onSubmit={(e) => {
                e.preventDefault();
                login();
              }}
            >
              <Stack spacing={1}>
                <FormControl>
                  <TextField
                    id='email-input'
                    name='email'
                    type='email'
                    onChange={(e) => handleChange(e)}
                    label='Почта'
                    required
                  />
                </FormControl>
                <PasswordInput
                  value={data.password}
                  onChange={(e) => handleChange(e)}
                  noRequired={true}
                />
                <FormControl>
                  <Button variant='contained' size='large' type='submit'>
                    Войти
                  </Button>
                </FormControl>
                {processing && <LinearProgress></LinearProgress>}
                <AuthAlert
                  text={info.text}
                  severity={info.severity}
                  timestamp={info.timestamp}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Не можешь зайти, не беда - зарегистрируйся или восстанови пароль.
            </Typography>
            <Button onClick={() => navigate('/register')}>
              Зарегистрироваться
            </Button>
            <Button onClick={() => navigate('/reset_password')}>
              Восстановить пароль
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
