import { ReadMore, CurrencyRuble } from '@mui/icons-material';
import GameTypeIcon from '../Shared/GameTypeIcon';
import { IconButton, Typography, Stack, Grid, Button } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { GetAddressString } from '../../Kernel/AddressHelper';

export default function PlaceMapCard(props) {
  const place = props.place;
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        width={300}
        spacing={0.5}
        alignItems='center'
        position={'relative'}
      >
        <Grid item xs={12} container spacing={1} alignItems='center'>
          <Grid item xs='auto'>
            <GameTypeIcon
              fontSize='large'
              gameTypeId={place.gameTypeId}
            ></GameTypeIcon>
          </Grid>
          <Grid item xs>
            <Typography
              variant='subtitle1'
              style={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {place.name}
            </Typography>
            <Typography variant='subtitle2' color={'#AAA'}>
              {place.gameTypeName}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            {props.selectionMode ? (
              <Button onClick={() => props.onSelectClick(place)}>
                Выбрать
              </Button>
            ) : (
              <IconButton onClick={() => navigate('/place/' + place.id)}>
                {/*<IconButton
                 onClick={() =>
                   window.open(window.location.origin + '/place/' + place.id)
                 }
              >*/}
                <ReadMore fontSize='large' color='primary'></ReadMore>
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1.5} alignItems='center'>
          <Grid item>
            <Stack direction={'row'} spacing={0.5} alignItems='center'>
              {place.isPaid && (
                <Grid item>
                  <CurrencyRuble />
                </Grid>
              )}
              <Typography variant='body2'>
                {GetAddressString(place.address)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction={'row'} spacing={0.5} alignItems='center'>
              <Typography
                variant='body2'
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {place.description}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
