import React, { useState, useEffect, useContext } from 'react';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import CreateGame from './CreateGame';
import GameFilter from './GameFilter';
import {
  LinearProgress,
  Tab,
  Tabs,
  Button,
  Grid,
  Box,
  Paper,
  Grow,
  Typography,
} from '@mui/material';
import {
  ViewAgendaOutlined,
  ExploreOutlined,
  AddOutlined,
  Search as SearchIcon,
} from '@mui/icons-material';
import OSMap, { MapDefaultZoom } from '../Maps/OSMap';
import { Stack } from '@mui/system';
import GameCard from './GameCard';
import GameMapCard from './GameMapCard';
import PlaceMarker from '../Maps/PlaceMarker';
import { Search, SearchIconWrapper, StyledInputBase } from '../Search/Search';
import useAuthFetch from '../../Hooks/useAuthFetch';
import { GetTodayString } from '../../Kernel/DateTimeHelper';
import GotoLoginDialog from '../Shared/GotoLoginDialog';
import { UserContext } from '../../Kernel/UserContext';

export default function Games() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('loading');
  const [games, setGames] = useState([]);
  const [gamesByPlaces, setGamesByPlaces] = useState([]);
  const user = useContext(UserContext);

  const defaultFilter = {
    dateFrom: GetTodayString(),
  };

  const [filter, setFilter] = useState(
    window.location.search.length > 0
      ? Object.fromEntries(new URLSearchParams(window.location.search))
      : defaultFilter
  );
  const [tab, setTab] = useState(localStorage.gamesPageTab ?? 'map');

  const [coords, setCoords] = useState({
    latitude: '59.952122',
    longitude: '30.315465',
  });
  const [mapZoom, setMapZoom] = useState(MapDefaultZoom);

  const authFetch = useAuthFetch();

  const fetchData = (ct) => {
    setStatus('loading');
    const searchParams = new URLSearchParams(filter).toString();
    const path =
      baseApiUrl() +
      '/games' +
      (tab === 'map' ? '/byplace' : '') +
      (searchParams.length > 0 ? '?' + searchParams : '');
    authFetch(path, { signal: ct.signal }).then((data) => {
      if (tab === 'map') setGamesByPlaces(data);
      else setGames(data);
      setStatus('show');
      if (searchParams.length > 0) {
        const refresh =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          '?' +
          searchParams;
        window.history.pushState({ path: refresh }, '', refresh);
      } else {
        const refresh =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname;
        window.history.pushState({ path: refresh }, '', refresh);
      }
    });
  };

  useEffect(() => {
    let controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, [filter, tab]);

  const onShowPlaceOnMap = (place) => {
    if (place !== undefined) {
      setCoords({ latitude: place.latitude, longitude: place.longitude });
      setMapZoom(15);
      setTab('map');
    }
  };

  const onCreateClicked = () => {
    if (!user.id) {
      setOpen(true);
    } else {
      setStatus('create');
    }
  };

  return (
    <>
      <Grid container spacing={4} justifyContent='center'>
        {status === 'create' ? (
          <Grow in={status === 'create'}>
            <Grid item xs={12} md={8} lg={6}>
              <CreateGame
                onBackClick={() => {
                  setStatus('show');
                }}
              />
            </Grid>
          </Grow>
        ) : (
          <>
            <Grid item xs={12} container justifyContent='center'>
              <Grid item xs={12} md={10}>
                <Box
                  component='form'
                  sx={{
                    mt: 2,
                    border: 1,
                    borderColor: '#031E49',
                    borderRadius: 2,
                  }}
                  noValidate
                  autoComplete='false'
                >
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder='Название игры…'
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={(e) => {
                        setFilter({ ...filter, name: e.target.value });
                      }}
                      value={filter.name ?? ''}
                    />
                  </Search>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Stack spacing={2}>
                <GameFilter
                  filter={filter}
                  setFilter={setFilter}
                  default={defaultFilter}
                />
                <Button
                  size='large'
                  variant='contained'
                  color='success'
                  onClick={onCreateClicked}
                  fullWidth
                  disabled={status === 'loading'}
                >
                  <AddOutlined sx={{ mr: 1 }} /> Создать игру
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Paper elevation={3}>
                <Tabs
                  value={tab}
                  variant='fullWidth'
                  onChange={(event, index) => {
                    setTab(index);
                    localStorage.gamesPageTab = index;
                  }}
                >
                  <Tab
                    icon={<ExploreOutlined />}
                    iconPosition='start'
                    label='Карта'
                    value='map'
                  />
                  <Tab
                    icon={<ViewAgendaOutlined />}
                    iconPosition='start'
                    label='Список'
                    value='list'
                  />
                </Tabs>
                {status === 'loading' && <LinearProgress />}
                {tab === 'map' && status !== 'create' && (
                  <OSMap
                    coords={coords}
                    zoom={mapZoom}
                    styles={{
                      height: '600px',
                    }}
                  >
                    {gamesByPlaces.length > 0 &&
                      gamesByPlaces.map((group) => (
                        <PlaceMarker
                          key={group.place.id}
                          position={[
                            group.place.latitude,
                            group.place.longitude,
                          ]}
                          iconType={group.games[0].game.gameTypeId}
                          typesCount={group.games.length}
                        >
                          {group.games.length > 0 &&
                            group.games.map((gameResponse, subindex) => (
                              <React.Fragment key={gameResponse.game.id}>
                                <GameMapCard
                                  key={gameResponse.game.id}
                                  game={gameResponse.game}
                                  participation={gameResponse.participationInfo}
                                ></GameMapCard>
                                {subindex < group.games.length - 1 && <hr></hr>}
                              </React.Fragment>
                            ))}
                        </PlaceMarker>
                      ))}
                  </OSMap>
                )}
                {status === 'show' &&
                  tab === 'list' &&
                  (games.length > 0 ? (
                    <Grid container spacing={2} p={2}>
                      {games.map((g) => (
                        <Grid key={g.game.id} item xs={12}>
                          <GameCard
                            game={g.game}
                            permissions={g.permissions}
                            participation={g.participationInfo}
                            onShowPlaceOnMap={onShowPlaceOnMap}
                          ></GameCard>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography
                      margin={3}
                      paddingBottom={2}
                      variant='h6'
                      textAlign={'center'}
                    >
                      Игры не найдены
                    </Typography>
                  ))}
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
      <GotoLoginDialog
        actionName={'Создание новой игры'}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}
