import React, { useState, useContext } from 'react';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { UserContext } from '../../Kernel/UserContext';

import { baseApiUrl } from '../../Kernel/RestApiHelper';
import AvatarUploader from './Settings/AvatarUploader';
import UserDataChanger from './Settings/UserDataChanger';
import EmailChanger from './Settings/EmailChanger';
import PasswordChanger from './Settings/PasswordChanger';
import useAuthFetch from '../../Hooks/useAuthFetch';

import SessionsManager from './Settings/SessionsManager';
import { NotificationContext } from '../../Kernel/NotificationContext';

export default function UserSettings(props) {
  const currentUser = useContext(UserContext);

  const [openEmailChanger, setOpenEmailChanger] = useState(false);
  const [openPasswordChanger, setOpenPasswordChanger] = useState(false);

  const { showNotification } = useContext(NotificationContext);

  const authFetch = useAuthFetch();

  const refreshUser = () => {
    authFetch(baseApiUrl() + '/user/' + currentUser.id).then((data) => {
      props.setUserContext(data);
    });
  };

  return (
    <Grid container spacing={2} justifyContent='center' marginTop={2}>
      <Grid item xs={12} sm={10} lg={8}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12}>
              <Typography variant='h4'>
                <b>Персональные данные</b>
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} container spacing={4} justifyContent='center'>
              <Grid item xs={10} sm={6} md={3}>
                <AvatarUploader user={currentUser} refreshUser={refreshUser} />
              </Grid>
              <Grid item xs={10} sm={9} md={6}>
                <UserDataChanger
                  currentUser={currentUser}
                  refreshUser={refreshUser}
                />
              </Grid>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h4'>
                <b>Настройки входа</b>
              </Typography>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={6}
              justifyContent='center'
              marginBottom={2}
            >
              <Grid item alignSelf={'center'}>
                <Typography>
                  E-mail: <b>{currentUser.email}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  size='large'
                  onClick={() => setOpenEmailChanger(true)}
                >
                  Изменить e-mail
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  size='large'
                  onClick={() => setOpenPasswordChanger(true)}
                >
                  Изменить пароль
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} marginBottom={2}>
              <Typography variant='h6' textAlign={'center'} mb={1}>
                Активные сессии
              </Typography>
              {currentUser && currentUser.id && (
                <SessionsManager userId={currentUser.id} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <EmailChanger
        open={openEmailChanger}
        setOpen={setOpenEmailChanger}
        userId={currentUser.id}
        refreshUser={refreshUser}
        showInfo={(severity, text) => showNotification(text, severity)}
      />
      <PasswordChanger
        open={openPasswordChanger}
        setOpen={setOpenPasswordChanger}
        userId={currentUser.id}
        refreshUser={refreshUser}
        showInfo={(severity, text) => showNotification(text, severity)}
      />
    </Grid>
  );
}
