import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import useAuthFetch from '../../Hooks/useAuthFetch';
import PlaceMarker from '../Maps/PlaceMarker';
import PlaceMapCard from './PlaceMapCard';
import OSMap from '../Maps/OSMap';
import {
  Checkbox,
  Grid,
  FormControlLabel,
  InputLabel,
  TextField,
  Typography,
  Box,
  Paper,
  Button,
  Stack,
} from '@mui/material';
import GameTypeSelector from '../Shared/GameTypeSelector';
import { GetAddressString } from '../../Kernel/AddressHelper';

export default function AddPlace(props) {
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(
    props.showMap === true || props.showMap === undefined
  );
  const [place, setPlace] = useState(
    props.preselectedGameType === undefined
      ? props.place === undefined
        ? {}
        : { ...props.place, gameType: props.place.gameTypeId }
      : { gameType: props.preselectedGameType }
  );
  const [gameTypes, setGameTypes] = useState([]);

  const authFetch = useAuthFetch();
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    authFetch(baseApiUrl() + '/gameTypes').then((data) => {
      setGameTypes(data);
      setLoading(false);
    });
  };

  const addPlace = () => {
    authFetch(baseApiUrl() + '/places', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        ...place,
        gameTypeId: place.gameType,
        ...place.address,
      }),
    }).then((data) => {
      if (props.selectionMode) {
        props.onSelectClick(data);
      } else {
        props.showPlaces(true);
      }
    });
  };

  const updatePlace = () => {
    authFetch(baseApiUrl() + '/place/' + props.place.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        ...place,
        gameTypeId: place.gameType,
        ...place.address,
      }),
    }).then((data) => {
      props.showPlaces(true);
      navigate('/place/' + props.place.id);
    });
  };

  const handleChange = (event) => {
    setPlace({ ...place, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAddress = (lat, lon) => {
    var url =
      'https://nominatim.openstreetmap.org/reverse?format=json&lat=' +
      lat +
      '&lon=' +
      lon +
      '&addressdetails=1';
    fetch(url, {
      method: 'GET',
      // mode: 'cors',
      // headers: {
      //   'Access-Control-Allow-Origin': 'https://o2cj2q.csb.app',
      // },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data !== undefined) {
          const adr = {
            country: data.address.country,
            region: data.address.state,
            city:
              data.address.city ?? data.address.town ?? data.address.village,
            road: data.address.road,
            house: data.address.house_number,
          };
          setPlace({
            ...place,
            address: adr,
            latitude: lat,
            longitude: lon,
          });
        }
      });
  };

  const onClickCoordsGetter = (location) => {
    if (
      location !== undefined &&
      location.latitude !== undefined &&
      location.longitude !== undefined
    ) {
      const latitude = Number(location.latitude).toFixed(6);
      const longitude = Number(location.longitude).toFixed(6);

      getAddress(latitude, longitude);
      setPlace({
        ...place,
        latitude: latitude,
        longitude: longitude,
      });
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
      <Box
        component={'form'}
        id='frmAddPlace'
        onSubmit={(e) => {
          e.preventDefault();
          props.update ? updatePlace(place) : addPlace();
        }}
      >
        <Grid container spacing={3} rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant='h4' textAlign={'center'}>
              {props.update ? 'Изменить локацию' : 'Добавить локацию'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Название'
              fullWidth
              size='small'
              name='name'
              required
              onChange={handleChange}
              value={place.name ?? ''}
            />
          </Grid>

          <Grid item xs={12} md>
            <GameTypeSelector
              value={place.gameType}
              handleChange={handleChange}
              gameTypes={gameTypes}
              required
              disabled={(props.preselectedGameType ?? 0) > 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={place.isPaid ?? false}
                  name='isPaid'
                  onChange={(event) => {
                    setPlace({
                      ...place,
                      [event.target.name]: event.target.checked,
                    });
                  }}
                />
              }
              label='Требуется оплата'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Описание'
              fullWidth
              multiline
              minRows={2}
              size='small'
              name='description'
              onChange={handleChange}
              value={place.description ?? ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' textAlign={'center'}>
              Укажите место на карте
            </Typography>
          </Grid>
          {showMap === true && (
            <Grid item xs={12}>
              <OSMap
                places={props.places.map((p) => p.place)}
                coords={{
                  latitude: place?.latitude ?? '59.952122',
                  longitude: place?.longitude ?? '30.315465',
                }}
                zoom={props.update === true ? 15 : 12}
                styles={{ height: '450px' }}
                onClickCoordsGetter={onClickCoordsGetter}
                //styles={{ width: "700px", height: "500px" }}
              >
                {props.update === true && place && (
                  <PlaceMarker
                    key={'PlaceMarker_' + place.id}
                    position={[place.latitude, place.longitude]}
                    iconType={place.gameTypeId}
                  >
                    <Typography>{place.name}</Typography>
                  </PlaceMarker>
                )}
                {props.update !== true &&
                  props.places.length > 0 &&
                  props.places.map((group, index) => (
                    <PlaceMarker
                      key={index}
                      position={[group.place.latitude, group.place.longitude]}
                      iconType={group.place.gameTypeId}
                    >
                      <PlaceMapCard key={index} place={group.place} />
                    </PlaceMarker>
                  ))}
                {props.update !== true &&
                  place.latitude !== undefined &&
                  place.longitude !== undefined && (
                    <PlaceMarker
                      key={-1}
                      position={[place.latitude, place.longitude]}
                      iconType={place.gameType}
                    >
                      <Typography>{'Выбранное место'}</Typography>
                    </PlaceMarker>
                  )}
              </OSMap>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label='Адрес'
              fullWidth
              multiline
              size='small'
              name='address'
              disabled
              onChange={handleChange}
              value={GetAddressString(place.address)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              <b>Координаты: </b>
              <Grid container spacing={2} mt={0.1}>
                <Grid item>
                  <TextField
                    label='Широта'
                    size='small'
                    name='latitude'
                    disabled
                    onChange={handleChange}
                    value={place.latitude ?? ''}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label='Долгота'
                    size='small'
                    name='longitude'
                    disabled
                    onChange={handleChange}
                    value={place.longitude ?? ''}
                  />
                </Grid>
              </Grid>
            </InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'}>
              <Button
                size='large'
                onClick={() => {
                  props.showPlaces(true);
                }}
              >
                Назад
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                type={'submit'}
                variant={'contained'}
                color={props.update ? 'primary' : 'success'}
                size={'large'}
              >
                {props.update ? 'Сохранить' : 'Добавить'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
