import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import GameTypeSelector from '../Shared/GameTypeSelector';
import useAuthFetch from '../../Hooks/useAuthFetch';
import { ToLocalIsoString } from '../../Kernel/DateTimeHelper';
import Places from '../Places/Places';
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ruRU } from '@mui/x-date-pickers/locales';

export default function CreateGame(props) {
  const [placePick, setPlacePick] = useState({
    show: false,
    value: props.game ? props.game.place.name : '',
  });

  const [game, setGame] = useState(
    props.game
      ? {
          ...props.game,
          gameType: props.game.gameTypeId,
          placeId: props.game.place.id,
          startTime: ToLocalIsoString(props.game.startTime),
        }
      : {
          gameType: '',
          duration: '01:00:00',
          startTime: dayjs(Date.now()).toISOString(),
        }
  );
  const [gameTypes, setGameTypes] = useState([]);
  const [loadingGameTypes, setLoadingGameTypes] = useState(true);
  const [playersCounts, setPlayersCount] = useState({
    maxPlayersUnlim: !game.maxPlayers || game.maxPlayers === -1,
    maxPlayers: game.maxPlayers ?? '',
    maxReserveUnlim: !game.maxReserve || game.maxReserve === -1,
    maxReserve: game.maxReserve ?? '',
    minPlayersUnlim: !game.minPlayers || game.minPlayers === -1,
    minPlayers: game.minPlayers ?? '',
  });

  const [hasErrors, setHasErrors] = useState(false);

  const navigate = useNavigate();
  const authFetch = useAuthFetch();

  const fetchGameTypes = () => {
    setLoadingGameTypes(true);
    authFetch(baseApiUrl() + '/gametypes').then((data) => {
      setGameTypes(data);
      setLoadingGameTypes(false);
    });
  };

  const prepareGameToSend = (g) => {
    const result = { ...g, startTime: new Date(g.startTime) };
    return JSON.stringify(result);
  };

  const createGame = () => {
    authFetch(baseApiUrl() + '/games', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: prepareGameToSend(game),
    }).then((data) => navigate('/game/' + data.id));
  };

  const updateGame = () => {
    authFetch(baseApiUrl() + '/game/' + props.game.id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: prepareGameToSend(game),
    }).then((data) =>
      props.afterUpdate
        ? props.afterUpdate(data)
        : navigate('/game/' + props.game.id)
    );
  };

  const handleChange = (event) => {
    var val = event.target.value;
    setGame(validate({ ...game, [event.target.name]: val }));
  };

  const handleUnlimCheck = (event, property) => {
    setPlayersCount({
      ...playersCounts,
      [property + 'Unlim']: event.target.checked,
    });
    setGame(
      validate({
        ...game,
        [property]: event.target.checked
          ? -1
          : Number(playersCounts[property] ?? -1),
      })
    );
  };

  const handlePlayersCounts = (event) => {
    setPlayersCount({
      ...playersCounts,
      [event.target.name]: Number(event.target.value),
    });
    setGame(
      validate({
        ...game,
        [event.target.name]: Number(event.target.value),
      })
    );
  };

  const validate = (game) => {
    let errors = false;
    if (new Date(game.startTime) < Date.now()) {
      errors = true;
    }

    setHasErrors(errors);
    return game;
  };

  useEffect(() => {
    fetchGameTypes();
  }, []);

  const placeSelectionDialog = (
    <Dialog
      open={placePick.show}
      onClose={() => {
        setPlacePick({ ...placePick, show: false });
      }}
      fullWidth
      maxWidth={'lg'}
    >
      <DialogTitle>
        <Typography variant='h4'>Выбрать место игры:</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': {
            width: 4,
            height: 4,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'ligthgray',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'gray',
            borderRadius: 2,
          },
        }}
      >
        <Places
          filterSettings={{
            preselectedGameType: game.gameType,
            hideGeoZone: true,
            blockGameType: true,
          }}
          selectionMode={true}
          onSelectClick={(place) => {
            setPlacePick({
              show: false,
              value: place.name,
            });
            setGame(validate({ ...game, placeId: place.id }));
          }}
        ></Places>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setPlacePick({ ...placePick, show: false });
          }}
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
        <Box
          component={'form'}
          onSubmit={(e) => {
            e.preventDefault();
            if (props.game) {
              updateGame();
            } else {
              createGame();
            }
          }}
        >
          <Grid container spacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4' textAlign={'center'}>
                {props.game ? 'Изменить игру' : 'Создать игру'}
              </Typography>
            </Grid>
            <Grid item xs={12} md>
              <TextField
                label='Название'
                fullWidth
                size='small'
                name='name'
                required
                onChange={handleChange}
                value={game.name ?? ''}
                inputProps={{ maxLength: 1000 }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              {loadingGameTypes ? (
                <Skeleton>
                  <GameTypeSelector gameTypes={gameTypes} />
                </Skeleton>
              ) : (
                <GameTypeSelector
                  value={game.gameType}
                  handleChange={handleChange}
                  gameTypes={gameTypes}
                  required
                ></GameTypeSelector>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Описание'
                multiline
                fullWidth
                size={'small'}
                minRows={2}
                name='description'
                onChange={handleChange}
                value={game.description ?? ''}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' textAlign={'center'}>
                Время и место
              </Typography>
            </Grid>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={'ru'}
              localeText={
                ruRU.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <Grid item md>
                <InputLabel required>Время начала</InputLabel>

                <DateTimePicker
                  name='startTime'
                  value={dayjs(game.startTime ?? '')}
                  onChange={(e) => {
                    setGame(validate({ ...game, startTime: e.toJSON() }));
                  }}
                  minDateTime={dayjs(Date.now())}
                />
              </Grid>
              <Grid item md>
                <InputLabel required>Продолжительность</InputLabel>
                <TimePicker
                  name='duration'
                  value={dayjs(game.duration, 'HH:mm:ss')}
                  onChange={(e) => {
                    setGame(
                      validate({ ...game, duration: e.format('HH:mm:ss') })
                    );
                  }}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6}>
              <InputLabel required>Место игры</InputLabel>
              <Stack direction={'row'}>
                <TextField
                  contentEditable={false}
                  value={placePick.value}
                  required
                ></TextField>
                <Button
                  onClick={() => setPlacePick({ ...placePick, show: true })}
                  disabled={!(game.gameType > 0)}
                >
                  Выбрать
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' textAlign={'center'}>
                Участники
              </Typography>
            </Grid>
            <Grid item xs>
              <InputLabel>Максимальное число игроков:</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={playersCounts.maxPlayersUnlim}
                    onChange={(e) => {
                      handleUnlimCheck(e, 'maxPlayers');
                    }}
                  />
                }
                label='Без ограничений'
              />
              <TextField
                size={'small'}
                type={'number'}
                inputProps={{ min: '1' }}
                name='maxPlayers'
                disabled={playersCounts.maxPlayersUnlim}
                onChange={handlePlayersCounts}
                value={
                  game.maxPlayers && game.maxPlayers > -1 ? game.maxPlayers : ''
                }
              ></TextField>
            </Grid>
            <Grid item xs>
              <InputLabel>Минимальное число игроков:</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={playersCounts.minPlayersUnlim}
                    onChange={(e) => {
                      handleUnlimCheck(e, 'minPlayers');
                    }}
                  />
                }
                label='Без ограничений'
              />
              <TextField
                size={'small'}
                type={'number'}
                inputProps={{ min: '0' }}
                name='minPlayers'
                disabled={playersCounts.minPlayersUnlim}
                onChange={handlePlayersCounts}
                value={
                  game.minPlayers && game.minPlayers > -1 ? game.minPlayers : ''
                }
              ></TextField>
            </Grid>
            <Grid item xs>
              <InputLabel>Размер резерва:</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={playersCounts.maxReserveUnlim}
                    onChange={(e) => {
                      handleUnlimCheck(e, 'maxReserve');
                    }}
                  />
                }
                label='Без ограничений'
              />
              <TextField
                size={'small'}
                type={'number'}
                inputProps={{ min: '0' }}
                name='maxReserve'
                disabled={playersCounts.maxReserveUnlim}
                onChange={handlePlayersCounts}
                value={
                  game.maxReserve && game.maxReserve > -1 ? game.maxReserve : ''
                }
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'}>
                <Button size='large' onClick={props.onBackClick}>
                  Назад
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  type={'submit'}
                  variant={'contained'}
                  color={props.game ? 'primary' : 'success'}
                  size={'large'}
                  disabled={hasErrors}
                >
                  {props.game ? 'Сохранить' : 'Создать'}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {placeSelectionDialog}
    </>
  );
}
