import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { baseApiUrl } from '../../Kernel/RestApiHelper';
import Dropdown from '../Shared/Dropdown';
import OSMap from '../Maps/OSMap';
import PlaceMarker from '../Maps/PlaceMarker';
import { GetUserName } from '../../Kernel/UserHelper';
import StartPersonalChatButton from '../Chats/StartPersonalChatButton';
import {
  Grid,
  Button,
  Typography,
  Divider,
  LinearProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Grow,
  Stack,
  ButtonGroup,
  Chip,
} from '@mui/material';
import {
  Delete,
  Edit,
  PersonAddAlt1,
  PersonRemoveAlt1,
} from '@mui/icons-material';
import { UserContext } from '../../Kernel/UserContext';
import CreateGame from './CreateGame';
import useAuthFetch from '../../Hooks/useAuthFetch';
import UserLink from '../Users/UserLink';
import { GetAddressString } from '../../Kernel/AddressHelper';
import { TimeStringToMs } from '../../Kernel/DateTimeHelper';
import GotoLoginDialog from '../Shared/GotoLoginDialog';
import dayjs from 'dayjs';

function Game() {
  const currentUser = useContext(UserContext);
  const [openCancel, setOpenCancel] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [edit, setEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState();
  const [participateButton, setParticipateButton] = useState({
    color: 'primary',
    disabled: true,
    text: 'Участвовать',
  });

  const [players, setPlayers] = useState({ participants: [], reserve: [] });
  const [playersLoading, setPlayersLoading] = useState(true);
  const id = useParams().id;

  const apiParticipants = baseApiUrl() + '/game/' + id + '/participants';

  const authFetch = useAuthFetch();

  const resources = {
    participate: 'Участвовать',
    cancel: 'Отменить',
    reserve: 'В резерв',
  };

  const fetchGameData = (ct) => {
    authFetch(baseApiUrl() + '/game/' + id, { signal: ct.signal }).then(
      (data) => {
        setGame({
          ...data.game,
          canEdit: data.permissions.canEdit,
          canDelete: data.permissions.canDelete,
          canParticipate: data.permissions.canParticipate,
        });
        setLoading(false);
      }
    );
  };

  const fetchPlayersData = (ct) => {
    const params = ct
      ? {
          signal: ct.signal,
        }
      : {};
    authFetch(apiParticipants, params).then((data) => {
      setPlayers(data);
      setPlayersLoading(false);
    });
  };

  const participate = () => {
    if (!currentUser.id) {
      setOpenLogin(true);
      return;
    }

    let methodName = '';
    let bodyContent = '';
    switch (participateButton.text) {
      case resources.participate:
      case resources.reserve:
        methodName = 'POST';
        break;
      case resources.cancel:
        methodName = 'DELETE';
        const me = players.participants
          .concat(players.reserve)
          .find((p) => p.user.id === currentUser.id);
        if (me) {
          bodyContent = JSON.stringify({ id: me.id });
        }
        break;
    }

    authFetch(apiParticipants, {
      method: methodName,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: bodyContent,
    }).then(() => fetchPlayersData());
  };

  const updateButton = () => {
    if (players && game) {
      if (
        players.participants
          .concat(players.reserve)
          .find((p) => p.user.id === currentUser.id)
      ) {
        setParticipateButton({
          color: 'error',
          text: resources.cancel,
          disabled: !game.canParticipate,
        });
      } else if (
        game.maxPlayers === -1 ||
        players.participants.length < game.maxPlayers
      ) {
        setParticipateButton({
          color: 'success',
          text: resources.participate,
          disabled: !game.canParticipate,
        });
      } else if (
        game.maxReserve > -1 &&
        players.reserve.length >= game.maxReserve
      ) {
        setParticipateButton({
          color: 'secondary',
          text: resources.participate,
          disabled: true,
        });
      } else {
        setParticipateButton({
          color: 'primary',
          text: resources.reserve,
          disabled: !game.canParticipate,
        });
      }
    }
  };

  const navigate = useNavigate();
  const handleChange = () => {};
  const handleDelete = () => {
    authFetch(baseApiUrl() + '/game/' + game.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(game),
    }).then(() => navigate('/games'));
  };

  const getGameSummary = (game) => {
    const start = dayjs(game.startTime);
    const durationSplitted = game.duration.split(':');

    const end = start
      .add(Number(durationSplitted[0]), 'hour')
      .add(Number(durationSplitted[1]), 'minute');

    const formattedStart = start.format('DD.MM.YY HH:mm');
    const formattedEnd = start.isSame(end, 'day')
      ? end.format('HH:mm')
      : end.format('DD.MM.YY HH:mm');
    return `${game.gameTypeName}. ${formattedStart} - ${formattedEnd}`;
  };

  useEffect(() => {
    let controller = new AbortController();
    fetchGameData(controller);

    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    let controller = new AbortController();
    fetchPlayersData(controller);

    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    updateButton();
  }, [players, game]);

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={3} justifyContent={'center'} marginTop={2}>
          {edit ? (
            <Grow in={edit}>
              <Grid item xs={12} md={8} lg={6}>
                <CreateGame
                  game={game}
                  onBackClick={() => setEdit(false)}
                  afterUpdate={() => {
                    setEdit(false);
                    const ct = new AbortController();
                    fetchGameData(ct);
                    fetchPlayersData(ct);
                  }}
                />
              </Grid>
            </Grow>
          ) : (
            <>
              <Grid item xs={12} lg={10} container>
                <Grid item xs>
                  <Typography
                    variant='h4'
                    style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                  >
                    <b>{game.name}</b>
                    {Date.now() - new Date(game.startTime) >
                      TimeStringToMs(game.duration) && (
                      <Chip sx={{ ml: 1 }} label='Игра завершена' />
                    )}
                  </Typography>
                  <Typography variant='h6'>
                    <i>{getGameSummary(game)}</i>
                  </Typography>
                </Grid>
                <Grid item xs='auto'>
                  {game.canEdit && (
                    <IconButton size='medium' onClick={(e) => setEdit(true)}>
                      <Edit fontSize='large' />
                    </IconButton>
                  )}
                  {game.canDelete && (
                    <IconButton
                      size='medium'
                      onClick={() => setOpenDelete(true)}
                    >
                      <Delete fontSize='large' />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Typography variant='h6'>Описание:</Typography>
                {game.description && (
                  <Typography>{game.description}</Typography>
                )}
                <Typography>
                  <Stack direction={'row'} alignItems={'center'}>
                    <b>Организатор:</b>
                    <ButtonGroup variant='text' sx={{ margin: 0.25 }}>
                      <UserLink user={game.creator} />
                      {currentUser.id != game.creator.id && (
                        <StartPersonalChatButton userId={game.creator.id} />
                      )}
                    </ButtonGroup>
                  </Stack>
                </Typography>
                <Typography>
                  <b>Адрес:</b> {GetAddressString(game.place.address)}
                </Typography>
                <OSMap
                  zoom={15}
                  places={[game.place]}
                  coords={{
                    latitude: game.place.latitude,
                    longitude: game.place.longitude,
                  }}
                  styles={{ height: '400px' }}
                >
                  <PlaceMarker
                    position={[game.place.latitude, game.place.longitude]}
                    iconType={game.gameTypeId}
                    typesCount={1}
                  >
                    <Typography>{game.place.name}</Typography>
                  </PlaceMarker>
                </OSMap>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Button
                  variant='contained'
                  color={participateButton.color}
                  size='large'
                  fullWidth={true}
                  disabled={participateButton.disabled}
                  onClick={() => {
                    if (participateButton.text === resources.cancel) {
                      setOpenCancel(true);
                    } else {
                      participate();
                    }
                  }}
                >
                  {participateButton.text === resources.cancel ? (
                    <PersonRemoveAlt1 sx={{ marginRight: 1 }} />
                  ) : (
                    <PersonAddAlt1 sx={{ marginRight: 1 }} />
                  )}
                  {participateButton.text}
                </Button>
                {playersLoading && <LinearProgress></LinearProgress>}
                {!playersLoading && (
                  <Grid container spacing={1} marginTop={0.5}>
                    <Grid item xs={12} md={6}>
                      <Dropdown
                        title={
                          'Участники (' +
                          getPlayersCount(
                            players.participants.length,
                            game.maxPlayers
                          ) +
                          ')'
                        }
                      >
                        {players.participants.map((p) => {
                          return <UserLink key={p.id} user={p.user} />;
                        })}
                      </Dropdown>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Dropdown
                        title={
                          'Резерв (' +
                          getPlayersCount(
                            players.reserve.length,
                            game.maxReserve
                          ) +
                          ')'
                        }
                      >
                        {players.reserve.map((p) => {
                          return <UserLink key={p.id} user={p.user} />;
                        })}
                      </Dropdown>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          <Dialog open={openCancel} onClose={() => setOpenCancel(false)}>
            <DialogTitle>Отменить участие?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Вы потеряете своё текущее место в очереди.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenCancel(false)} autoFocus>
                Назад
              </Button>
              <Box sx={{ flex: 1 }}></Box>
              <Button
                color='error'
                onClick={() => {
                  setOpenCancel(false);
                  participate();
                }}
              >
                Отменить участие
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
            <DialogTitle>Удалить игру?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Это действие нельзя будет отменить.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDelete(false)} autoFocus>
                Назад
              </Button>
              <Box sx={{ flex: 1 }}></Box>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  setOpenDelete(false);
                  handleDelete();
                }}
              >
                Удалить
              </Button>
            </DialogActions>
          </Dialog>

          <GotoLoginDialog
            actionName={'Участие в игре'}
            open={openLogin}
            setOpen={setOpenLogin}
          />
        </Grid>
      )}
    </>
  );
}

export function getPlayersCount(length, maxLength) {
  return maxLength > -1 ? length + '/' + maxLength : length + '/∞';
}

export function gameDescription(props) {
  return (
    <p>
      {new Date(props.startTime).toLocaleString()} - <b>{props.name}</b>{' '}
      {props.place.address}
    </p>
  );
}

export default Game;
